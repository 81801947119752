import React from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Back = ( { url }) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if(url) {
            navigate(url);
        }else{
            navigate(-1);
        }
    };

    return (
        <IconButton onClick={handleBack}>
            <ArrowBackIcon />
        </IconButton>
    );
};

export default Back;

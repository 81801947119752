import http from '../http';

import { observable, action, makeObservable,runInAction } from "mobx";

class PackageStore {
  packages = [];

  constructor() {
    makeObservable(this, {
      packages: observable,
      fetchPackages: action,
      deletePackage: action,
    });
  }

  fetchPackages = () => {
    // fetch data
    http.get('/user/packages')
      .then(response => {
        runInAction(() => {
            this.packages = response.data;
          });
      })
      .catch(error => {});
  }

  deletePackage = (id) => {
    // delete package and then fetch again
    http.delete(`/user/packages/${id}`).then(()=>{
      this.fetchPackages();
    }).catch(error => {});
  }
}

const packageStore = new PackageStore();

export {packageStore}

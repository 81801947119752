import { Box, Button, Card, Dialog, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import http from "../http";
import theme from '../theme';
import { getSpaceUsedInUnit } from "../util";

//
const ChargeDialog = inject('chargeStore','userStore')(observer(({ userStore,chargeStore }) => {


    const [member, setMember] = React.useState(null);
    const [memberAninal, setMemberAninal] = React.useState(null);
    const [vip, setVip] = React.useState(null);
    const [vipAninal, setVipAninal] = React.useState(null);

    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if(!userStore.username) return;
        http.get("/user/products").then((res) => {
            let product = res.data.products;
            setMember(product.find(p => p.code === 'TUPAI_1_MONTH_MEMBERSHIP'))
            setMemberAninal(product.find(p => p.code === 'TUPAI_12_MONTH_MEMBERSHIP'))
            setVip(product.find(p => p.code === 'TUPAI_1_MONTH_VIP_MEMBERSHIP'))
            setVipAninal(product.find(p => p.code === 'TUPAI_12_MONTH_VIP_MEMBERSHIP'))
        })
    }, [userStore.username])

    const purchase = useCallback((productId) => {
        http.post("/user/subscription",{
            productId: productId,
            payType:"alipay"
        }).then((response) => {
            let aliResponse = response.data.alipay
            const newWin = window.open('', '_blank');
            // 将Alipay的HTML内容写入新窗口
            newWin.document.write(aliResponse);
            
        }).catch((err) => {
            console.log(err);
        })
    },[])
    
    const goToSubscriptions = useCallback((e) => {
        chargeStore.closeChargeDialog()
        navigate('/user/subscriptions')
    },[navigate,chargeStore])

    return <Dialog
        open={!!chargeStore.chargeDialogOpen}
        onClose={() => { chargeStore.closeChargeDialog() }}
        maxWidth="lg"
        PaperProps={{
            style: {
                backgroundColor: 'transparent',
            },
        }}
    >
        <Box sx={{
            backgroundColor: "white",
            minWidth: 800,
            p: 2
        }}>
            <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                <Typography variant="h3">{t("Subscribe Update")}</Typography>
                <Button onClick={goToSubscriptions}>{t("Subscriptions List")}</Button>
            </Box>
            <Box sx={{display:"flex",flexDirection:"row",justifyContent:"center"}}>
                <Card sx={{ p:5,m:2  }}>
                    <Typography variant='h3'>{t('Member')}</Typography>
                    <Typography variant='body2' sx={{mt:2}} color={theme.palette.secondary.main}>{t('for individuals')}</Typography>
                    <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",mt:3}}>
                        <Typography variant='h6'  color="#666" >¥ {member?.price} / <span style={{fontSize:"0.75rem"}}>{member?.duration} {t('Month')}</span> </Typography>
                        <Button variant="contained" sx={{ ml: 5 }} onClick={() => { purchase(member?.id) }}>{t('Subscribe')}</Button>
                    </Box>
                    <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",mt:3}}>
                        <Typography variant='h6' color="#666"  >¥ {memberAninal?.price} / <span style={{fontSize:"0.75rem"}}>{memberAninal?.duration} {t('Month')}</span> </Typography>
                        <Button variant="contained" sx={{ ml: 5 }} onClick={() => { purchase(memberAninal?.id) }}>{t('Subscribe')}</Button>
                    </Box>
                    
                    <Typography variant='h6' sx={{ mt: 4 }}>{t("Include")} </Typography>
                    <ul style={{paddingInlineStart:20}}>
                        <li> <Typography variant='body2'>{t('Storage')} {getSpaceUsedInUnit(member?.spaceLimit)} </Typography></li>
                        <li> <Typography variant='body2'>{t('MaxDimenstion')} {member?.maxDimension} </Typography></li>
                    </ul>
                </Card>
                <Card sx={{ p:5,m:2 }}>
                    <Typography variant='h3'>{t('VIP')}</Typography>
                    <Typography variant='body2' sx={{mt:2}} color={theme.palette.secondary.main}>{t('for company')}</Typography>
                    
                    <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",mt:3}}>
                        <Typography variant='h6'  color="#666" >¥ {vip?.price} / <span style={{fontSize:"0.75rem"}}>{vip?.duration} {t('Month')}</span> </Typography>
                        <Button variant="contained" sx={{ ml: 5 }} onClick={() => { purchase(vip?.id) }}>{t('Subscribe')}</Button>
                    </Box>
                    <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between",mt:3}}>
                        <Typography variant='h6' color="#666"  >¥ {vipAninal?.price} / <span style={{fontSize:"0.75rem"}}>{vipAninal?.duration} {t('Month')}</span> </Typography>
                        <Button variant="contained" sx={{ ml: 5 }} onClick={() => { purchase(vipAninal?.id) }}>{t('Subscribe')}</Button>
                    </Box>
                    <Typography variant='h6' sx={{ mt: 4 }}>{t("Include")} </Typography>
                    <ul style={{paddingInlineStart:20}}>
                        <li> <Typography variant='body2'>{t('Storage')} {getSpaceUsedInUnit(vip?.spaceLimit)} </Typography></li>
                        <li> <Typography variant='body2'>{t('MaxDimenstion')} {vip?.maxDimension} </Typography></li>
                    </ul>
                </Card>
            </Box>
        </Box>
    </Dialog>
}))

export default ChargeDialog;

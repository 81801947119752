import React, { useState, useEffect, useRef } from 'react';
import { userStore } from '../stores/UserStore';
import { useNavigate } from 'react-router-dom';
import http from '../http';
import { Box, FormControl, Button, TextField, Link, Typography,FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import logo from '../assets/logo.png';
import theme from '../theme';
import LanguageSwitcher from '../components/LanguageSwitcher';
import ICP from '../components/ICP';
import { passwordRegex, phoneRegex } from '../const';
import { set } from 'mobx';

import wechat_logo  from '../assets/wechat_logo.svg';

const UserLoginPage = inject('snackbarStore')(observer(({ snackbarStore }) => {
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState(undefined);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(undefined);
  const [wxLogin, setWxLogin] = useState(false);
  const [wxLoginStatus, setWxLoginStatus] = useState('pending'); // ['waiting','success','pending'
  const [wxLoginQrCode, setWxLoginQrCode] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  let intervalId = useRef(null); 

  const validatePhone = (phone) => {
    if (!phone) {
      return t("phone num can not be empty")
    }
    if (!phoneRegex.test(phone)) {
      return t("_Phone Rule")
    }
    return '';
  }

  const validatePassword = (password) => {
    if (!password) {
      return t("password can not be empty")
    }
    if( !passwordRegex.test(password)) {
      return t("_Password Rule")
    }
    return '';
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === 'phone') {
      setPhone(value);
      setPhoneError(validatePhone(value));
    }else if(name === 'password'){
      setPassword(value);
      setPasswordError(validatePassword(value));
    }
  };

  const handleWxLogin = async () => {
    const local_login_session_id = localStorage.getItem('login_session_id');
    let data = {}
    if (local_login_session_id) {
      data = {
        login_session_id:local_login_session_id
      }
    }
    const response = await http.post('/wxLoginQrCode',{
      ...data
    });
    const {imageData,login_session_id} = response.data;
    localStorage.setItem('login_session_id',login_session_id);
    setWxLogin(true);
    setWxLoginQrCode(imageData);

    intervalId.current = setInterval(async ()=>{
      const response = await http.get(`/wxLoginStatus/${login_session_id}`).catch(e=>{
        setWxLogin(false);
        setWxLoginQrCode('');
        clearInterval(intervalId.current);
        localStorage.removeItem('login_session_id');
      });
      let status = response?.data?.status;
      setWxLoginStatus(status);
      
      if( status.indexOf('success') > -1 ){
        //匹配类似 `success:${token}` 的字符串
        let token = status.split(':')[1];
        clearInterval(intervalId.current);
        userStore.login(token);
        snackbarStore.showSnackbar(t('Login successfully'))
        navigate('/user/orders');
      }
    },1000);
  }

  const goBack = () => {
    setWxLogin(false);
    if (intervalId.current) {
      clearInterval(intervalId.current);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPhoneError(validatePhone(phone));
    setPasswordError(validatePassword(password));
    if (phoneError || passwordError) {
      return;
    }

    try {
      const response = await http.post('/login', {
        phone,
        password,
      });

      userStore.login(response.data.token);
      snackbarStore.showSnackbar(t('Login successfully'))

      navigate('/user/orders');
    } catch (error) {
      error?.response?.data?.message && snackbarStore.showSnackbar(t(error.response.data.message), 'error');
    }
  }

   useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);


  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ position: "absolute", top: 10, right: 35 }}>
          <LanguageSwitcher />
        </Box>
        <Box sx={{ width: 500, backgroundColor: "#fff", p: 5 }}>
          <Box display="flex" justifyContent="center" mb={2}>
            <img alt='logo' src={logo} style={{ color: theme.palette.primary.main, height: 145 }} />
          </Box>

          {!wxLogin && <Box>
            <form onSubmit={handleSubmit}>

              <FormControl margin="normal" fullWidth>
              < FormLabel>{t("phone")}</FormLabel>
                <TextField
                  placeholder={t("phone")}
                  size='small'
                  name='phone'
                  value={phone}
                  error={!!phoneError}
                  helperText={phoneError || undefined}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl margin="normal" sx={{ mt: 0 }} fullWidth>
              < FormLabel>{t("Password")}</FormLabel>
                <TextField
                  placeholder={t("Password")}
                  value={password}
                  name='password'
                  size='small'
                  type='password'
                  error={!!passwordError}
                  helperText={passwordError || undefined}
                  onChange={handleInputChange}
                />
              </FormControl>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  mt: 2,
                }}
              >
                <Button variant="contained" fullWidth color="primary" type="submit">
                  {t("Sign in")}
                </Button>
                <Button  sx={{mt:1}} variant="contained" fullWidth color="wechat" onClick={handleWxLogin} >
                  <img src={wechat_logo} alt="Wechat Logo" width={24.77} height={20} style={{marginRight:10}} />
                  {t("Wechat Sign in")}
                </Button>
              </Box>
              <Box sx={{ mt: 1, display: "flex", flexDirection: "row", justifyContent: 'space-between' }}>
                <Typography variant="body2" align='left' mb={2}> <Link href='/signup' > {t("Sign up for free")}</Link></Typography>
                <Typography variant="body2" align='left' mb={2}> <Link href='/forgetPassword' >{t("Forget Password")}</Link></Typography>

              </Box>
            </form>
          </Box> }
          {wxLogin && <Box sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <Box
              sx={{
                position:"relative"
              }}
            >
              <img width={280} height={280} src={`data:image/png;base64,${wxLoginQrCode}`} alt="Wechat Login QR Code" />
              { wxLoginStatus == "scaned" && <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left:  0,
                  width: "280px",
                  height: "280px",
                  background: "rgba(255,255,255,0.8)",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant='h3' sx={{textAlign:"center",fontWeight:"bolder"}}>{t("Already scaned")}</Typography>
              </Box>}
            </Box>
            <Button sx={{mt:3}} variant="contained" fullWidth color="primary" onClick={goBack} >
                  {t("Go Back")}
            </Button>
          </Box>}
        </Box>
        <Box sx={{ pt: 10 }}>
          <ICP />
        </Box>

      </Box>
    </Box>

  );
}))

export default UserLoginPage;

import { IconButton, Menu, MenuItem,Box } from '@mui/material';
import {ReactComponent as ChinaFlag } from '../assets/chinaFlag.svg';
import {ReactComponent as EnglishFlag } from '../assets/englishFlag.svg';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

function LanguageSwitcher(props) {
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    const handleEnglishClick = () => {
      i18n.changeLanguage('en');
      handleClose();
    };
    
    const handleChineseClick = () => {
      i18n.changeLanguage('zh');
      handleClose();
    };

    return (
      <Box  sx={{
        display: 'inline-flex',
        alignItems: 'center', 
        justifyContent: 'center',
        ...props.sx
      }}>
        <IconButton size="small" onClick={handleClick}>
          {i18n.language==='zh'? <ChinaFlag />:<EnglishFlag /> }
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <MenuItem onClick={handleEnglishClick}>
            <IconButton size="small">
             <EnglishFlag />
            </IconButton>
          </MenuItem>
          <MenuItem onClick={handleChineseClick}>
            <IconButton size="small">
             <ChinaFlag />
            </IconButton>
          </MenuItem>
        </Menu>
      </Box>
    );
}

export default LanguageSwitcher;

import http from '../http';

import { observable, action, makeObservable, runInAction, computed } from "mobx";

class OrdersStore {
    orders = [];

    page = 1;  // 当前页码

    itemsPerPage = 10;  // 每页的订单数量

    currentOrder = null;

    constructor() {
        makeObservable(this, {
            orders: observable,
            currentOrder: observable,
            page: observable,
            setCurrentOrder:action,
            fetchOrders: action,
            deleteOrder: action,
            searchOrders: action,
            setPage: action,
            paginatedOrders: computed,
            totalPage: computed,
        });
    }

    setCurrentOrder = (order) => {
        this.currentOrder = order;
    }

    setPage= (newPage) => {
        this.page = newPage;
    }

    get totalPage(){
        return Math.ceil(this.orders.length / this.itemsPerPage);
    }

    get paginatedOrders() {
        return this.orders.slice((this.page - 1) * this.itemsPerPage, this.page * this.itemsPerPage);
    }

    fetchOrders = () => {
        // fetch data
        http.get('/user/orders')
            .then(response => {
                runInAction(() => {
                    this.orders = response.data;
                });
            })
            .catch(error =>{});

    }

    searchOrders = (searchType, searchText) => {
        if(searchText === ''){
            this.fetchOrders();
            return;
        }
        http.get('/user/orders/search', {
            params: {
                type: searchType,
                searchText: searchText,
            }
        }).then(response => {
            runInAction(() => {
                this.orders = response.data;
            });
        })
            .catch(error => {} );
    }

    deleteOrder = (id) => {
        // delete package and then fetch again
        http.delete(`/user/order/${id}`).then(() => {
            this.fetchOrders();
        })
    }
}

const ordersStore = new OrdersStore();

export { ordersStore }

// src/locales/en.js
const lang = {
    'Sign in': 'Sign in',
    'Orders' :  'Orders',
    'Packages' : 'Packages',
    'Package List': 'Package List',
    'Create Package': 'Create Package',
    'Package Name': 'Package Name',
    'Photo Limit': 'Photo Limit',
    'Price': 'Price',
    'No packages available.': 'No packages available.',
    'Submit': 'Submit',
    'Edit': 'Edit',
    'Edit Package': 'Edit Package',
    'Are you sure you want to delete this package?' :'Are you sure you want to delete this package?',
    'Cancel': 'Cancel',
    'Confirm': 'Confirm',
    'Package created successfully': 'Package created successfully',
    'Package updated successfully': 'Package updated successfully',
    'Create Order': 'Create Order',
    'Edit Order': 'Edit Order',
    'Order List': 'Order List',
    'No orders available.': 'No orders available.',
    'Customer Name': 'Customer Name',
    'Customer Phone': 'Customer Phone',
    'Customer Info': 'Customer Info',
    'Shoot Location': 'Shoot Location',
    'Remark': 'Remark',
    'Album Title': 'Album Title',
    'Shoot Time': 'Shoot Time',
    'Oprations': 'Oprations',
    'Upload Pictures': 'Upload Pictures',
    'Order Management': 'Order Management',
    'Select File': 'Select File',
    'No picture available.': 'No picture available.',
    'Picture Management': 'Picture Management',
    'Order Detail': 'Order Detail',
    'Share To Customer': 'Share To Customer',
    'Go Back': 'Go Back',
    'Share': 'Share',
    'Shared Link': 'Shared Link',
    'Password': 'Password',
    'Close': 'Close',
    'Copy url and password to clipboard': 'Copy url and password to clipboard',
    'Link': 'Link',
    'Verify Code': 'Verify Code',
    'Enter Code': 'Enter Code',
    'Selected': 'Selected',
    'Unselected': 'Unselected',
    'Select detail': 'Select detail',
    'Available': 'Available',
    'Picture selection limit reached.': 'Picture selection limit reached.',
    'User Info': 'User Info',
    'User Name': 'User Name',
    'Change Password': 'Change Password',
    'Passwords do not match!': 'Passwords do not match!',
    'Confirm Password': 'Confirm Password',
    'Password changed successfully': 'Password changed successfully',
    'Sign In': 'Sign In',
    'Sign Up': 'Sign up',
    '_Password Rule': 'Password should be at least 6 characters, and no more than 16 characters. It can include letters (case-insensitive), numbers, and special characters (@$!%*#?&).',
    '_Username Role':'Username should be at least 4 characters, and no more than 20 characters. It can only include letters (case-insensitive) and numbers',
    '_Phone Rule': 'Phone number should be 11 digits',
    'Invalid username or password': 'Invalid username or password',
    'Login successfully': 'Login successfully',
    'Regesiter successfully': 'Regesiter successfully',
    'User alreay exists': 'User alreay exists',
    'Please input customer name': 'Please input customer name',
    'Please select a package': 'Please select a package',
    'Upload Files': 'Upload Files',
    'Are you sure you want to delete this order?': 'Are you sure you want to delete this order?',
    'User Agreement': 'User Agreement',
    'Loading': 'Loading',
    'Text copied to clipboard': 'Text copied to clipboard',
    'Failed to copy text.': 'Failed to copy text.',
    'No picture is uploading.': 'No picture is uploading.',
    'InvitationCode can not be null!': 'InvitationCode can not be null!',
    'Invitation Code': 'Invitation Code',
    'Invitation code error': 'Invitation code error',
    'No token provided.': 'No login info provided.',
    'Failed to authenticate token.': 'Failed to authenticate token.',
    'Package' : 'Package',
    'alias' : 'alias',
    'Welcome back,' : 'Welcome back,',
    'Don’t have an account?': 'Don’t have an account?',
    'Sign up for free': 'Sign up for free',
    'Create Account': 'Create Account',
    'Already have an account?': 'Already have an account?',
    'Login': 'Login',
    'By clicking Sign up, you agree to our': 'By clicking Sign up, you agree to our',
    'Terms and Privacy Policy.': 'Terms and Privacy Policy.',
    '2 days':  '2 days',
    '3 days':  '3 days',
    '7 days':  '7 days',
    '14 days': '14 days',
    '30 days':  '30 days',
    '{{day}} days lef': '{{day}} days lef',
    'Expire': 'Expire',
    'Expired':"Expired",
    'Save Share': 'Save Share',
    'Cancel Share': 'Cancel Share',
    'Password format error': 'Password format error',
    'Package Name is required': 'Package Name is required',
    'Photo Limit format is incorrect': 'Photo Limit format is incorrect',
    'Price format is incorrect': 'Price format is incorrect',
    'Password must be 4 characters long and only contain letters and numbers': 'Password must be 4 characters long and only contain letters and numbers',
    'Send Code': 'Send Code',
    'phone': 'phone',
    '_Captcha Rule' : 'Captcha should be 4 characters',
    'Phone code' : 'Phone code',
    'Captcha': 'Captcha',
    'Forget Password': 'Forget Password',
    'Reset Password': 'Reset Password',
    'phone num can not be empty': 'phone num can not be empty',
    'password can not be empty': 'password can not be empty',
    'Code can not be empty': 'Code can not be empty',
    'Captcha can not be empty': 'Captcha can not be empty',
    'Captcha format error': 'Captcha format error',
    'Code format error': 'Code format error',
    'Reset password successfully': 'Reset password successfully',
    'package name can not be empty': 'package name can not be empty',
    'photoCount can not be empty': 'photoCount can not be empty',
    'photoCount must be number': 'photoCount must be number',
    'price can not be empty': 'price can not be empty',
    'price must be number': 'price must be number',
    'photoCount must be less than 1000': 'photoCount must be less than 1000',
    'price must be less than 100000': 'price must be less than 100000',
    'customer name can not be empty': 'customer name can not be empty',
    'customer phone is invalid': 'customer phone is invalid',
    ' seconds remaining': ' seconds remaining',
    'Invalid phone or password': 'Invalid phone or password',
    'Password is wrong': 'Password is wrong',
    'Subscription end date': 'Subscription end date',
    'Upgrade': 'Upgrade',
    'No products': 'No products',
    'Subscribe Update': 'Subscribe Update',
    'Month': 'Month',
    'Duration': 'Duration',
    'Space Limit': 'Space Limit',
    'Purchase': 'Purchase',
    'Total Price': 'Total Price',
    'No subscriptions': 'No subscriptions',
    'Subscriptions List': 'Subscriptions List',
    'Status': 'Status',
    'pending': 'pending',
    'completed': 'completed',
    'Amount': 'Amount',
    'Alipay Order Number': 'Alipay Order Number',
    'Order Number': 'Order Number',
    'Subscription Time': 'Subscription Time',
    'No such subscription': 'No such subscription',
    'Subscription payment status': 'Subscription payment status',
    'Refresh Status': 'Refresh Status',
    'Image dimensions exceed the maximum allowed size.':'Image dimensions exceed the maximum allowed size.',
    'File name is:':'File name is:',
    'The max with and height of your picture will be optimized to:':'The max with and height of your picture will be optimized to:',
    'pixels':'pixels',
    'If you want to use a bigger picture, please':'If you want to use a bigger picture, please',
    'File is not a jpg or png image.':'File is not a jpg or png image.',
    'Upload File': 'Upload File',
    'Claim Album': 'Claim Album',
    'Unclaim Album': 'Unclaim Album',
    'Album claimed successfully.': 'Album claimed successfully.',
    'Album unclaimed successfully.': 'Album unclaimed successfully.',
    'Customer Selected': 'Customer Selected',
    'You have {{hours}} hours left to use this platform.' : 'You have {{hours}} hours left to use this platform.',
    'You have {{days}} days left to use this platform.': 'You have {{days}} days left to use this platform.',
    'Your subscribe is expired': 'Your subscribe is expired',
    'The picture will only be available for the next {{day}} days. Please make your choice soon!': 'The picture will only be available for the next {{day}} days. Please make your choice soon!',
    'Already the first picture': 'Already the first picture',
    'Last picture': 'Last picture',
    'Uploaded pictures': 'Uploaded pictures',
    'pics': 'pics',
    'Claimed pictures': 'Claimed pictures',
    'selectable images': 'selectable images',
    'Share detail': 'Share detail',
    'Shared': 'Shared',
    'claimed': 'claimed',
    'not shared': 'not shared',
    'days': 'days',
    'Member': 'Member',
    'Member plan': 'Member plan',
    'for individuals': 'for individuals',
    'Include': 'Include',
    'Storage': 'Storage',
    'MaxDimenstion': 'MaxDimenstion',
    'VIP': 'VIP',
    'for company': 'for company',
    'Subscribe': 'Subscribe',
    'code cannot be empty': 'code cannot be empty',
    'code is wrong': 'code is wrong',
    'Code': 'Code',
    'You are not confirm your choose,Are you sure you want to leave this page?': 'You are not confirm your choose,Are you sure you want to leave this page?',
    'Upload Complete': 'Upload Complete',
    'Watermark': 'Watermark',
    'Style': 'Style',
    'Single': 'Single',
    'Fill':'Fill',
    'Watermark can not be empty': 'Watermark can not be empty',
    'Save': 'Save',
    'Watermark saved successfully': 'Watermark saved successfully',
    'Watermark must less than 5 letters': 'Watermark must less than 5 letters',
    'Order Info': 'Order Info',
    'Related Package' : 'Related Package',
    'Message Board' :'Message Board',
    'If you have any suggestions or complaints, please feel free to leave us a message.':"If you have any suggestions or complaints, please feel free to leave us a message.",
    'Enter your message here' :'Enter your message here',
    'Reply type':'Reply type',
    'callback':'callback',
    'wechat':'wechat',
    'messageBoard':'messageBoard',
    'Content is too long':'Content is too long',
    'Content can not be empty':'Content can not be empty',
    'Message submited successfully':"Message submited successfully",
    'Admin says:':'Admin says:',
    'add my wechat':'add my wechat',
    'call me please.':"call me please.",
    'Basic Member':"Basic Member",
    'Intermediate Member':"Intermediate Member",
    'Advanced Member':"Advanced Member",
    'UserName updated!':"UserName updated!",
    'Delete':"Delete",
    'Set as cover':"Set as cover",
    'Preview':"Preview",
    'Cover':"Cover",
    'You are currently subscribed to the {{plan}}, which offers images at a resolution of {{resolution}}. You have the option to upgrade to the {{nexPlan}} for higher quality images.':"You are currently subscribed to the {{plan}}, which offers images at a resolution of {{resolution}}. You have the option to upgrade to the {{nexPlan}} for higher quality images.",
    'Multi select':"Multi select",
    'Are you sure you want to delete those pictures?':"Are you sure you want to delete those pictures?",
    'Commit select':"Commit select",
    'Select again': "Select again",
    'Picture Preview':"Picture Preview",
    'Select this picture':"Select this picture",
    'Unselect this picture':"Unselect this picture",
    'Wrong password':"Wrong password",
    'Username':'Username',
    'Change Name':'Change name',
    '_Username Rule':'Username should be at least 6 characters and at most 20 characters long',
    'Generate':'Generate',
    'Copy Names': 'Copy Names',
    'Text copied to clipboard': 'Text copied to clipboard',
    'Error to write to clipboard': 'Error to write to clipboard',
    'Learn how to filter selected photos in Lightroom.': 'Learn how to filter selected photos in Lightroom.',
    'Open Selection Tool': 'Open Selection Tool',
    //error from server
    'Internal Server error, please try again later.' : 'Internal Server error, please try again later.',
    'share was expired' :  'share was expired',
    'Album not found' :  'Album not found',
    'Picture selection limit reached.' : 'Picture selection limit reached.',
    'Order or album not found' : 'Order or album not found',
    'Wrong password': 'Wrong password',
    'Invalid phone or password':  'Invalid phone or password',
    'Captcha expired':  'Captcha expired',
    'Captcha error': 'Captcha error',
    'Phone alreay exists':  'Phone alreay exists',
    'Code was expired': 'Code was expired',
    'Send successfully': 'Send successfully',
    'Code already sent':  'Code already sent',
    'Code error or expired':  'Code error or expired',
    'Package is in use. please delete the orders first.': 'Package is in use. please delete the orders first.',
    'Wechat Sign in': 'Wechat Sign in',
    'Already scaned': 'Already scaned',
    'Open files from computer': 'Open files from computer',
    'Drop files here to view and convert' : 'Drop files here to view and convert',
    'Add a file': 'Add a file',
    'Download this image': 'Download this image',
    'Processing': 'Processing',
    'The image is not supported': 'The image is not supported',
    'Uploading {{num}} pictures': 'Uploading {{num}} pictures',
    'Setting': 'Setting',
    'Support': 'Support',
    'My': 'My',
    'Position': 'Position',
    'Top Left': 'Top Left',
    'Top Right': 'Top Right',
    'Bottom Left': 'Bottom Left',
    'Bottom Right': 'Bottom Right',
    'Center': 'Center',
    'Font Size': 'Font Size',
    'Small': 'Small',
    'Medium': 'Medium',
    'Large': 'Large',
    'X-Large': 'X-Large',
    'Selection Setting': 'Selection Setting',
    'Selection Result': 'Selection Result',
    'Archive': 'Archive',
    'Open local directory failed': 'Open local directory failed',
    'Local files': 'Local files',
    'Write to a new directory': 'Write to a new directory',
    'Write to new directory successfully': 'Write to new directory successfully',
    'Write to new directory failed': 'Write to new directory failed',
    'Filter': 'Filter',
    'show image name': 'show image name',
    'force limit': 'force limit',
    'append picture price': 'append picture price',
    'Update Success': 'Update Success',
    'over-selection allowed' : 'over-selection allowed',
    'over-select {{over}} pics already' : 'over-select {{over}} pics already',
    'need pay {{price}} Yuan' : ',need pay {{price}} Yuan',
    'Image information': 'Image information',
    'Brightness': 'Brightness',
    'Contrast': 'Contrast',
    'Hue': 'Hue',
    'Saturation': 'Saturation',
    'Value': 'Value',
    'Temperature': 'Temperature',
    'Alpha': 'Alpha',
    'Watermark setting': 'Watermark setting',
    'Watermark has been set': 'Watermark has been set',
    'Every photo uploaded from now on will be marked with the watermark.': 'Every photo uploaded from now on will be marked with the watermark.',
    'Set it again': 'Set it again',
    'random': 'random',
    'Code must be 4 numbers': 'Code must be 4 numbers',
    'available for the next {{day}} days': 'available for the next {{day}} days',
    'share link has been set': 'share link has been set',
    'share link not been set': 'share link not been set',
    'Do not metion me again': 'Do not metion me again',
    'Preview selection pdf': 'Preview selection pdf',
    'Preview selection': 'Preview selection',
    'Settlement Statement': 'Settlement Statement',
    'Learn how to sperate picture.': 'Learn how to sperate picture.',
    'Are you sure you want to leave this page? The upload processing not be done.': 'Are you sure you want to leave this page? The upload processing not be done.',
    '(Optional)': '(Optional)',
    'Copy image to clipboard': 'Copy image to clipboard',
    'Copy image faild': 'Copy image faild',
    'Copy image success': 'Copy image success',
    'Auto fill password': 'Auto fill password',
    'Pictures': 'Pictures',
    'Out of space and you can:': 'Out of space and you can:',
    'Upgrade to a higher plan': 'Upgrade to a higher plan',
    'You have 5G spaces to get': 'You have 5G spaces to get',
    'Or': 'Or',
    'Request more space': 'Request more space',
    'extra space added': 'extra space added',
    'force limit(no extra pics)': 'force limit(no extra pics)',
    'Next Step': 'Next Step',
    'pricePerPic can not be empty': 'pricePerPic can not be empty',
    'pricePerPic must be number': 'pricePerPic must be number',
    'OpenWatermark': 'OpenWatermark',
    'User not yet selected': 'User not yet selected',
    'Global Watermark Setting': 'Global Watermark Setting',
    'The watermark will be enabled when the next file is uploaded.': 'The watermark will be enabled when the next file is uploaded.',
    'Miniprogram selection program, easy to use': 'Miniprogram selection program, easy to use',
    'Open with miniprogram': 'Open with miniprogram',
    "Select setting can't be modified if user selected.": "Select setting can't be modified if user selected.",
    "Photos": "Photos",
    'Space Used': 'Space Used',
    'counting...' : 'counting...',
    'Reset Selection': 'Reset Selection',
    'Selections Logs': 'Selections Logs',
    'Are you sure to commit those selections?': 'Are you sure to commit those selections?',
    'Are you sure to reset those selections?': 'Are you sure to reset those selections?',
    'Delivery': 'Delivery',
    'negative': 'negative',
    'retouching': 'retouching',
    'Delivery Share': 'Delivery Share',
    'The picture will only be available for the next {{day}} days. Please save your photo soon!': 'The picture will only be available for the next {{day}} days. Please save your photo soon!',
    'Download link details': 'Download link details',
    'Generate Link': 'Generate Link',
    'Last generate time': 'Last generate time',
    'Not generate yet': 'Not generate yet',
    'Photo numer when generate is': 'Photo numer when generate is',
    'Download link': 'Download link',
    'Generating download link': 'Generating download link',
    'The number of images allowed in the package is limited to {{number}} in each order.':'The number of images allowed in the package is limited to {{number}} in each order.',
    'You have generated {{number}} download links, and you can generate {{limit}} more.':'You have generated {{number}} download links, and you can generate {{limit}} more.',
    'Save file to local': 'Save file to local',
    'Resume': 'Resume',
    'Download': 'Download',
    'Download again': 'Download again',
    'There are no photos in the album.': 'There are no photos in the album.',
    'Selection': 'Selection',
    'Member Only': 'Member Only',
    'One picture can only appear in one order. So if you upload a picture that already exists in the order, it will be ignored.': 'One picture can only appear in one order. So if you upload a picture that already exists in the order, it will be ignored.',
    'Open local directory canceled': 'Open local directory canceled',
    'Open local directory failed': 'Open local directory failed',
    'Select a directory to save': 'Select a directory to save',
    'Pack images': 'Pack images',
    'Download': 'Download',
    'Something wrong with write file ,Please use pack file button': 'Something wrong with write file ,Please use pack file button',
    'Done': 'Done',
    'Download completed': 'Download completed',
    'Add to nagetive folder of dilivery at the same time while uploading, And deletion will be synced too.': 'Add to nagetive folder of dilivery at the same time while uploading, And deletion will be synced too.',
    'The number of pictures in the negative folder of the delivery is limited to {{num}}.': 'The number of pictures in the negative folder of the delivery is limited to {{num}}.', 
    'Uploaded {{pictureNum}} pictures, seccess: {{successFileCount}} ,fail: {{failFileCount}},exists {{existFileCount}}.':'Uploaded {{pictureNum}} pictures, seccess: {{successFileCount}} ,fail: {{failFileCount}},exists {{existFileCount}}.',
    'Uploaded {{pictureNum}} pictures to nagetive folder of dilivery, seccess: {{successPhotoCount}}, fail:{{failPhotoCount}} ,exists {{existPhotoCount}}.':'\nUploaded {{pictureNum}} pictures to nagetive folder of dilivery, seccess: {{successPhotoCount}}, fail:{{failPhotoCount}} ,exists {{existPhotoCount}}.',
    'The number of pictures you can upload is limited to {{limit}}': 'The number of pictures you can upload is limited to {{limit}}',
    'Select a Video from computer': 'Select a Video from computer',
    'A video is processing': 'A video is processing',
    'A free account is limited to the creation of only one order.': 'A free account is limited to the creation of only one order.',
    'A free account is limited to 300 pictures.': 'A free account is limited to 300 pictures.',
    'Delivery Shared': 'Delivery Shared',
    'Selected pictures num is:': 'Selected pictures num is:',
    'Total num is:': 'Total num is:',
    'Delivery pictures num is:': 'Delivery pictures num is:',
    'Subscription End Date': 'Subscription End Date',
    'WeChat browser does not support downloading images, please open link in your brower.': 'WeChat browser does not support downloading images, please open link in your brower.',
    'Copy url to clipboard': 'Copy url to clipboard',
    'PhotoShared Link': 'PhotoShared Link',
    'Details': 'Details',
    'Selection Detail': 'Selection Detail',
    'Selection Records': 'Selection Records',
    'Redirect to miniprogram to select pictures.': 'Redirect to miniprogram to select pictures.',
  };

  export default  lang;
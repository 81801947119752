import React, { useState, useEffect, useCallback } from 'react';
import { TextField,FormLabel, Box, IconButton,InputAdornment } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import http from '../http';


function CaptchaInput(props) {
  const [captchaSvg, setCaptchaSvg] = useState('');
  const [captchaRight, setCaptchaRight] = useState(false); // 验证码是否正确
  const {captchaID, setCaptchaID} = props; // 验证码 ID，用于提交验证
  const {captcha, setCaptcha} =props; // 验证码

 

  const fetchCaptcha = useCallback(async () => {
    try {
      const response = await http.get('/captcha'); // 获取 SVG 数据
      setCaptchaID(response.data.captchaID)
      setCaptchaSvg(response.data.captchaImage); // 假设 response.data 是 SVG 字符串
    } catch (error) { }
  },[setCaptchaID,setCaptchaSvg])

  useEffect(() => {
    fetchCaptcha();
  }, [fetchCaptcha]);

  const checkCaptcha = async (captcha) => {
    try {
      const response = await http.post('/captcha', { captchaID,captcha }); // 提交验证码
      return response.data; // 假设 response.data 是 true 或 false
    } catch (error) {}
  }

  return (
    <Box display="flex" alignItems="center" sx={{width:"100%",mt:1}}>
      <TextField 
        placeholder={props.placeholder}
        error={props.error}
        helperText={props.helperText}
        size='small'
        sx={{flex:1}}
        value={captcha}
        name='captcha'
        onChange={async (e) => {
          const captcha = e.target.value;
          setCaptcha(captcha);
          props.onChange && props.onChange(e);
          //防抖
          if(captcha.length < 4) return;
          const result = await checkCaptcha(captcha);
          if( result?.success ) {
            setCaptchaRight(true);
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={fetchCaptcha}>
                <RefreshIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {captchaRight ? <CheckOutlinedIcon /> : captcha.length === 4 && <ClearOutlinedIcon /> }
      <Box ml={1} dangerouslySetInnerHTML={{ __html: captchaSvg }} />
    </Box>
  );
}

export default CaptchaInput;

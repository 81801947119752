import { Button, Box, IconButton, Tooltip, Popover,TextField } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getFileNameWithoutExtension } from '../util';

const CopyNames = inject('snackbarStore')(observer(({ snackbarStore, pictures }) => {

    const { t } = useTranslation();
    const [showHelp, setShowHelp] = useState(false);
    const [showNoPermission, setShowNoPermission] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    let names = pictures.map((picture) => getFileNameWithoutExtension(picture.fileName)).join(',');

    const handleCopyNames = useCallback((event) => {
        setAnchorEl(event.currentTarget);
        if (navigator.clipboard) {
            navigator?.clipboard.writeText(names).then(() => {
                snackbarStore.showSnackbar(t('Text copied to clipboard'));
            }).catch((error) => {
                setShowNoPermission(true);
                snackbarStore.showSnackbar(t('Error to write to clipboard'), 'error');
            });
        } else {
            setShowNoPermission(true);
            snackbarStore.showSnackbar(t('Error to write to clipboard'), 'error');
        }

    }, [pictures, snackbarStore, t]);

    const goToLink = useCallback((e) => {
        window.open('https://support.qq.com/products/640899/faqs-more/?id=153854', '_blank');
        e.stopPropagation();
    }, []);

    const handleClose = () => {
        setShowNoPermission(false);
    }

    if (!pictures) {
        return <div>Loading...</div>;
    }

    const id = open ? 'simple-popover' : undefined;

    return (
        <Box sx={{ mr: 2 }}
            onMouseEnter={() => setShowHelp(true)}
            onMouseLeave={() => setShowHelp(false)}
        >
            {showHelp && <Tooltip title={t("Learn how to filter selected photos in Lightroom.")}>
                <IconButton sx={{ pl:1,pr:1 }} onClick={goToLink}>
                    <HelpOutlineIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
            </Tooltip>}
            <Button aria-describedby="copyname-popover"  variant="outlined" size='small' color="secondary"
                onClick={handleCopyNames} >
                {t('Copy Names')}
            </Button>

            <Popover
                id="copyname-popover"
                open={showNoPermission}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                >
                <TextField
                    multiline
                    rows={4}
                    defaultValue={names}
                />
             </Popover>


        </Box>

    )
}));
export default CopyNames;
import { Box, Button,Snackbar,Alert,Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, {  useEffect,useState,useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {readFileAsDataURL} from '../../util';
import { v4 } from 'uuid';

const FileUploader = inject('pictureStore', 'photoStore' ,'userStore','snackbarStore')(observer(({sx,size,onStartUpload,onUploaded, pictureStore, photoStore,userStore,snackbarStore,

  albumId,
  openWatermark,
  watermarkStyle,
  watermark,
  watermarkAlpha,
  watermarkPosition,
  watermarkFontSize,
  copyToDelivery
}) => {
  const { t } = useTranslation();

  const workerRef = useRef(null);
  const [open,setOpen] = useState(false)
  const [message,setMessage] = useState("")
  const [errorFileName,setErrorFileName] = useState("")

  useEffect(() => {
    workerRef.current = new Worker(new URL('../worker.js', import.meta.url));
      return () => {
          if (workerRef.current) {
              workerRef.current.terminate();
          }
      };
  }, []);

  const callUnpackRaw = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const rawData = e.target.result;
        workerRef.current.onmessage = (e) => {
          resolve(e.data);
        };
        workerRef.current.postMessage({ rawData: rawData, fileName:file.name, type:"thumbnail" }, [rawData])
      };
      reader.readAsArrayBuffer(file);
    });
  }

  const beforeUnload = (e)=>{
      const confirmationMessage = t("Are you sure you want to leave this page? The upload processing not even be done.");
      e.returnValue = confirmationMessage;  // 兼容一些旧浏览器
      return confirmationMessage;
  }

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    if( userStore.level == 0 && pictureStore.pictures.length > 200 ){
      snackbarStore.showSnackbar(t("A free account is limited to 200 pictures."),'error');
      return
    }

    onStartUpload(files.length);
    window.addEventListener('beforeunload', beforeUnload);
    let successFileCount = 0;
    let existFileCount = 0;
    let failFileCount = 0;
    let successPhotoCount = 0;
    let existPhotoCount = 0;
    let failPhotoCount = 0;
    for (let file of files) {
      let fileType = file.type;
      let fileUrl 
      let fileSize = file.size;
      // Check MIME type
      if (!['image/jpeg', 'image/png'].includes(fileType)) {
        let rawImageData = await callUnpackRaw(file);
        if( rawImageData.status == "success" ){
          let thumbnailData = rawImageData.data
          fileType = "image/jpeg"
          fileSize = thumbnailData.length
          const blob = new Blob([thumbnailData], {type: fileType});
          fileUrl = URL.createObjectURL(blob);
        }else{
          setMessage('Unsupported file type')
          setErrorFileName(file.name)
          setOpen(true)
          return
        }
      }else{
        try{
          fileUrl = await readFileAsDataURL(file)
        }catch(e){
          failFileCount ++;
        }
      }

      let uuid = v4();
      let status = await pictureStore.addPicture( fileUrl,fileSize, uuid, file.name.trim(), fileType,  albumId,
        openWatermark,
        watermarkStyle,
        watermark,
        watermarkAlpha,
        watermarkPosition,
        watermarkFontSize
      );
      if( status == "success" ){
        successFileCount++;
      }else if( status == "fail" ){
        failFileCount++;
      }else if( status == "exist" ){
        existFileCount++;
      }
     
      if(copyToDelivery ){
        let statusPhoto = await photoStore.addFile( fileUrl, uuid, file.name.trim(), fileType, albumId, "negative")
        if( statusPhoto == "success" ){
          successPhotoCount++;
        }else if( statusPhoto == "fail" ){
          failPhotoCount++;
        }else if( statusPhoto == "exist" ){
          existPhotoCount++;
        }
      }
      
    }
    onUploaded({
      pictureNum: files.length,
      successFileCount,
      existFileCount,
      failFileCount,
      copyToDelivery,
      successPhotoCount,
      existPhotoCount,
      failPhotoCount
    });
    window.removeEventListener('beforeunload', beforeUnload);

  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };


  return (
    <Box sx={sx}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical:"top", horizontal:"center" }}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          <Typography variant='body1'>{t(message)}</Typography>
          <Typography variant='body2'>{t('File name is:')}{errorFileName}</Typography>
        </Alert>
      </Snackbar>

      <Button variant="contained" size={size} component="label">
        {t('Upload File')}
        <input type="file" multiple hidden onChange={handleFileChange} />
      </Button>
    </Box>

  );
}));

export default FileUploader;

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

export default function withAuth(WrappedComponent) {
  const Wrapper = inject('userStore')(observer(({ userStore, ...props }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { shouldRedirectToLogin } = userStore;

    useEffect(() => {
      if (shouldRedirectToLogin) {
        navigate('/login', { state: { from: location } });
      }

    }, [navigate, location, shouldRedirectToLogin]);
    // 如果用户已登录，则渲染被包装的组件，否则渲染 null
    return <WrappedComponent {...props} />;
  }));

  return Wrapper;
}

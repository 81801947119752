function getTitle(language,customerName,shootLocation,shootTime) {
    let title = customerName
    if(language === 'zh' || language === 'zh-CN') {
      if(shootLocation){
        title += `在 ${shootLocation}` 
      }
      if(shootTime){
        title += `拍摄于 ${shootTime.format('YYYY-MM-DD')}`
      }
    }else{
      if(shootLocation){
        title += ` shoot at ${shootLocation}`
      }
      if(shootTime){
        title += ` of ${shootTime.format('YYYY-MM-DD')}`
      }
    }
    return title
  }

  export {getTitle}
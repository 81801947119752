import React from 'react';
import { Box, Typography, lighten } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import theme from '../theme';
import { useTranslation } from 'react-i18next';

const Logo = ({logo,sx,home}) => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleBack = () => {
        navigate(home)
    };
    const background_color = lighten(theme.palette.primary.main,0.6)
    const background_color_end = theme.palette.primary.main
    return (
        <Box onClick={handleBack} sx={{...sx,width:240,cursor:"pointer",display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
            <img src={logo} alt='logo'  style={{ marginTop:"8px", color: theme.palette.primary.main, height: 50 }} />
            <Typography 
                color="primary" 
                style={{ 
                    color: theme.palette.primary.main, 
                    fontSize: '1.6rem', 
                }}>{t("picsect")}</Typography>
            <div 
                style={{ 
                    marginLeft:"5px",
                    marginTop:"-8px",
                    color: "white",
                    backgroundImage: `linear-gradient( 135deg,   ${background_color} 0%,  ${background_color_end} 100%)`,
                    height: "15px",
                    width: "35px",
                    textAlign: "center",
                    lineHeight: "15px",
                    fontSize: '0.6rem', 
                    borderRadius: "5px",
                }}
            >BETA</div>
        </Box>
    );
};

export default Logo;

import { Alert, Box, Button, Dialog, Tab, Tabs, DialogTitle, DialogContent, DialogContentText,DialogActions, Typography, Grid, FormControl, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import CustomTabLabel from '../../components/CustomTabLabel';
import Empty from '../../components/Empty';
import { CustomerImageDialog } from './CustomerImageDialog';
import { CustomerImageGallery } from './CustomerImageGallery';
import { PasswordVrifyDialog } from './PasswordVrifyDialog';
import http from '../../http';
import { isWeChatBrowser } from '../../util';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';


function ConfirmDialog({ open, onConfirm, title, onClose }) {
    const { t } = useTranslation();

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"确认操作"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {title}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("Cancel")}</Button>
                <Button onClick={handleConfirm} autoFocus>
                    {t("Confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}


const OrderSelect = inject('snackbarStore', 'anonymousUserStore')(observer(({ anonymousUserStore, snackbarStore }) => {
    const { orderId } = useParams();
    const [searchParams] = useSearchParams();
    const pwd = searchParams.get('pwd');
    const [order, setOrder] = useState(null);
    const [needpassword, setNeedpassword] = useState(true);
    const [albumClaimed, setAlbumClaimed] = useState(false);
    const [multiMode, setMultiMode] = useState(false)
    const [expiredDay, setExpiredDay] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [pictures, setPictures] = useState([])
    const [selectedPicturesIds, setSelectedPicturesIds] = useState([])
    const [preview, setPreview] = useState(null);
    const [currentPicIndex, setCurrentPicIndex] = useState(0);
    const [miniProgramUrl, setMiniProgramUrl] = useState(null)
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
    const [confirmTitle, setConfirmTitle] = useState('')
    const [activeConfirm, setActiveConfirm] = useState(() => () => {});

    const { t } = useTranslation();

    const selectedPictrues = useMemo(() => {
        return pictures.filter(picture => selectedPicturesIds.includes(picture.id))
    }, [pictures, selectedPicturesIds])

    const unSelectedPictrues = useMemo(() => {
        return pictures.filter(picture => !selectedPicturesIds.includes(picture.id))
    }, [pictures, selectedPicturesIds])

    const shownPictrus = useMemo(() => {
        switch (tabValue) {
            case 0:
                return pictures
            case 1:
                return selectedPictrues
            case 2:
                return unSelectedPictrues
            default:
                return pictures
        }

    }, [pictures, selectedPictrues, unSelectedPictrues, tabValue])

    const fetchOrderData = useCallback(() => {
        if (anonymousUserStore.accessToken) {
            http.get(`/customer/order/${orderId}/select`, {
                headers: {
                    "x-access-token": anonymousUserStore.accessToken
                }
            }).then(response => {
                setNeedpassword(false)
                let dataPictrues = response.data?.pictures
                setPictures(dataPictrues)
                let localStorageSelectedPictures = window.localStorage.getItem(`pictrue_selection_${orderId}`)
                if (localStorageSelectedPictures) {
                    setSelectedPicturesIds(JSON.parse(localStorageSelectedPictures))
                } else {
                    setSelectedPicturesIds(dataPictrues.filter(picture => picture.claimed).map(picture => picture.id))
                }
                let orderData = response.data?.order
                setOrder(orderData);
                document.title = orderData?.Album.title;
                let expiredTime = new dayjs(orderData?.Album?.expiredTime)
                let now = new dayjs()
                let diff = expiredTime.diff(now, 'day')
                setExpiredDay(diff)
                setAlbumClaimed(orderData?.Album?.claimed)

            }).catch(error => {
                // 处理错误的响应
                anonymousUserStore.empty()
            });
        }

    }, [orderId, anonymousUserStore]);

    const handlePictureSelect = useCallback((pictureId, claimed) => {
        //claimed is the new state of the picture
        let newSelectedPictures = [...selectedPicturesIds]
        if (claimed) {
            newSelectedPictures.push(pictureId)
        } else {
            let index = newSelectedPictures.indexOf(pictureId)
            if (index > -1) {
                newSelectedPictures.splice(index, 1)
            }
        }
        if (order.forceLimit && newSelectedPictures.length > order.photoCount) {
            snackbarStore.showSnackbar(t('Picture selection limit reached.'), 'warning')
            return
        } else {
            setSelectedPicturesIds(newSelectedPictures)
            window.localStorage.setItem(`pictrue_selection_${orderId}`, JSON.stringify(newSelectedPictures))
        }
        return;

    }, [selectedPicturesIds, order]);

    const resetAlbum = () => {
        http.get(`/customer/order/${orderId}/reset`, {
            headers: {
                "x-access-token": anonymousUserStore.accessToken
            }
        }).then(response => {
            setSelectedPicturesIds([])
            setAlbumClaimed(false)
            window.localStorage.removeItem(`pictrue_selection_${orderId}`)
        }).catch(error => {
        });
    }

    const claimAlbum = (claimed) => {

        http.post(`/customer/order/${orderId}/claim`, { claimed: claimed, selectedPictureIds: selectedPicturesIds }, {
            headers: {
                "x-access-token": anonymousUserStore.accessToken
            }
        })
            .then(response => {
                if (claimed) {
                    window.localStorage.removeItem(`pictrue_selection_${orderId}`)
                    snackbarStore.showSnackbar(t('Album claimed successfully.'))
                } else {
                    snackbarStore.showSnackbar(t('Album unclaimed successfully.'))
                }
                setMultiMode(false)
                setAlbumClaimed(claimed)
            })
            .catch(error => {
            });

    }

    const handleCloseDialog = () => {
        setOpenConfirmDialog(false);
    };
    const openDialog = (title,confirmAction,prams) => {
        setActiveConfirm(() => () => confirmAction(prams));
        setConfirmTitle(title); // 设置对话框标题
        setOpenConfirmDialog(true);
    };

    useEffect(() => {
        if (!orderId || !anonymousUserStore.accessToken) {
            return
        }
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
            http.get(`/customer/order/${orderId}/miniprogramUrl?pwd=${pwd}`, {
                headers: {
                    "x-access-token": anonymousUserStore.accessToken
                }
            }).then(response => {
                if (response.data.url) {
                    setMiniProgramUrl(response.data.url)
                    if(isWeChatBrowser()){
                        window.location.href = response.data.url
                        return
                    }
                }
            }).catch(error => {
            })
        }
    }, [orderId, anonymousUserStore.accessToken])

    useEffect(() => {
        if (needpassword) {
            return
        }
        anonymousUserStore.check()
        fetchOrderData();
    }, [fetchOrderData, anonymousUserStore, needpassword]);

    if (needpassword) {
        return <PasswordVrifyDialog open={needpassword} pwd={pwd} orderId={orderId} fetchOrderData={fetchOrderData} anonymousUserStore={anonymousUserStore} />
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    if(isWeChatBrowser()){
        return <Empty
            message={t("Redirect to miniprogram to select pictures.")}
        >
            <HourglassTopIcon sx={{ fontSize: 40 }} />
        </Empty>
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <Box >
                <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label={<CustomTabLabel label="全部" num={pictures.length} />} />
                    <Tab label={<CustomTabLabel label="已选" num={selectedPictrues.length} />} />
                    <Tab label={<CustomTabLabel label="未选" num={unSelectedPictrues.length} />} />
                </Tabs>
                {miniProgramUrl && <Alert severity="info" sx={{ width: '100%', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", p: 0, pl: 2 }}>
                    <Typography variant='body1' >{t("Miniprogram selection program, easy to use")} <Button variant='contained' sx={{ ml: 1 }} color='primary' size='small'
                        onClick={() => {
                            window.location.href = miniProgramUrl
                        }}
                    >{t("Open with miniprogram")}</Button></Typography>

                </Alert>}
                <Alert severity="warning" sx={{ width: '100%' }}>
                    <Typography variant='body1'>{t("The picture will only be available for the next {{day}} days. Please make your choice soon!", { day: expiredDay })}</Typography>
                </Alert>
            </Box>


            <Box sx={{
                paddingBottom: "50px",
                overflowY: "scroll",
                flex: 1,
                m: {
                    xs: 1,
                },
            }}>
                {shownPictrus?.length > 0 ? <Grid container spacing={1} >
                    {shownPictrus?.map((picture, index) => (
                        <CustomerImageGallery
                            handlePreview={() => {
                                if (!multiMode) {
                                    setPreview(true)
                                    setCurrentPicIndex(index)
                                }
                            }}
                            showImageName={order.showImageName}
                            picture={picture}
                            key={picture.id}
                            isSelected={selectedPicturesIds.includes(picture.id)}
                            multiMode={multiMode}
                            albumClaimed={albumClaimed}
                            handlePictureSelect={handlePictureSelect}
                        />
                    ))}
                </Grid> : (<Empty message={t("No picture available.")} />)}

            </Box>
            <Box sx={{ width: "100vw" }}>
                <Box sx={{
                    p: 2,
                    background: 'white',
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                        <Typography variant='body1'
                            sx={{ fontSize: "0.75rem" }}
                        >{t('Selected')} : {selectedPicturesIds.length} / {order.photoCount}  {t('pics')}</Typography>
                        {!order.forceLimit && <Typography variant='body1'
                            sx={{ fontSize: "0.75rem" }}
                        >
                            {selectedPicturesIds.length <= order.photoCount ? t('over-selection allowed') : t('over-select {{over}} pics already', { over: selectedPicturesIds.length - order.photoCount })}
                            {selectedPicturesIds.length > order.photoCount && <span>,{t('need pay {{price}} Yuan', { price: ((selectedPicturesIds.length - order.photoCount) * order.pricePerPic) })}</span>}
                        </Typography>}
                    </Box>
                    <Box sx={{
                        mt: 1,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: {
                            xs: "space-between",
                            md: "flex-end"
                        }
                    }}>
                        {!albumClaimed && <Button variant="contained"
                            sx={{
                                fontSize: '0.9rem',
                                flex: 1,
                                maxWidth: "180px",
                                marginRight: 1
                            }}
                            onClick={() => {
                                setMultiMode(!multiMode)
                            }}>
                            {multiMode ? t('Cancel') : t('Multi select')}
                        </Button>}
                        {albumClaimed && <Button onClick={() => { openDialog(t("Are you sure to reset those selections?"),resetAlbum) }} variant="text" color={"secondary"}>
                            {t("Reset Selection")}
                        </Button>}
                        <Button variant="contained" color={albumClaimed ? "secondary" : "primary"}
                            sx={{
                                fontSize: '0.9rem',
                                flex: 1,
                                maxWidth: albumClaimed ? "370px" : "180px"
                            }}
                            onClick={() => {
                                if( albumClaimed ){
                                    claimAlbum(false)
                                }else{
                                    openDialog(t("Are you sure to commit those selections?"),claimAlbum,true)
                                }
                            }}>
                            {albumClaimed ? t('Select again') : t('Commit select')}
                        </Button>
                    </Box>

                </Box>
            </Box>
            <CustomerImageDialog
                albumClaimed={albumClaimed}
                open={preview}
                setOpen={setPreview}
                previewPictures={shownPictrus}
                photoCount={order.photoCount}
                currentIndex={currentPicIndex}
                setCurrentIndex={setCurrentPicIndex}
                selectedPicturesIds={selectedPicturesIds}
                handlePictureSelect={handlePictureSelect}
            />
           <ConfirmDialog 
                title={confirmTitle}
                open={openConfirmDialog} 
                onConfirm={activeConfirm} 
                onClose={handleCloseDialog} 
            />
        </Box>
    );
}));

export default OrderSelect;



import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { Avatar, Box, IconButton, Typography, TextField } from '@mui/material';
import { generateColor } from "../util"
import { getShortName } from '../util';
import SpaceUsage from './SpaceUsage';
import http from '../http';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


const CustomAvatar = inject('userStore', 'snackbarStore')(observer(({ userStore, snackbarStore }) => {

    const navigate = useNavigate()
    const [editMode, setEditMode] = useState(false);
    const [userName, setUserName] = useState("")
    const [shortUsername, setShortUsername] = useState("")

    let level = userStore.level

    const { t } = useTranslation()

    const goToAvatarPage = () => {
        navigate('/user/info')
    }

    useEffect(() => {
        let username = userStore.username
        if (!username) return;
        setUserName(username)
        setShortUsername(getShortName(username))
    }, [userStore.username, setShortUsername])



    const changeUserName = useCallback(async () => {
        if (!userStore.username) return
        if (userName != userStore.username) {
            const response = await http.post("/user/changeUserName", {
                username: userName
            })
            if (response.status == 200) {
                snackbarStore.showSnackbar(t("UserName updated!"))
                userStore.username = userName
            }
        }
        setEditMode(false)
    }, [userStore.username, userName])

    const bgColor = generateColor(userName);
    return <Box sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                pr:3,
                cursor: "pointer"
            }}
            onClick={goToAvatarPage}
        >
        <Box sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
        }}>
            <Box sx={{  display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", }}>
                    <Typography variant='subtitle2'>{userName}</Typography>
                </Box>
            </Box>
        </Box>
        <SpaceUsage />
    </Box >
}))

export default CustomAvatar;

import { Box, Button, FormControl, MenuItem, Select, TextField,FormLabel } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { phoneRegex } from "../../const"


const OrderForm = ({ handleSubmit,
  packages,
  packageId, setPackageId,
  customerName, setCustomerName,
  customerPhone, setCustomerPhone,
  photoCount, setPhotoCount,
  shootTime, setShootTime,
  shootLocation, setShootLocation,
  remark, setRemark
}) => {
  const { t } = useTranslation();
  const [customerNameError, setCustomerNameError] = useState(false);
  const [customerPhoneError, setCustomerPhoneError] = useState(false);
  const [photoCountError, setPhotoCountError] = useState(false);

  function isNumber(value) {
    return /^(0|[1-9][0-9]*)$/.test(value);
  }


  const validateCustomerName = (customerName) => {
    if (!customerName) {
      return t("customer name can not be empty");
    }
    return '';
  }

  const validateCustomerPhone = (customerPhone) => {
    if( customerPhone.length>0 && !phoneRegex.test(customerPhone)) { 
      return t("customer phone is invalid");
    }
    return '';
  }

  const validatePhotoCount = (photoCount) => {
    if (!photoCount) {
      return t("photoCount can not be empty")
    }
    if( !isNumber(photoCount)) {
      return t("photoCount must be number")
    }
    if( photoCount > 1000) {
      return t("photoCount must be less than 1000")
    }
    return '';
  }

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    if( typeof(value) == 'string' ){
      value = value.trim()
    }
    if (name === 'packageId') {
      setPackageId(value);
      if (value>0) {
        let packageItem = packages.find(item => item.id === value)
        setPhotoCount(packageItem.photoCount)
      }else{
        setPhotoCount(999)
      }
    }else if(name === 'customerName'){
      setCustomerName(value);
      setCustomerNameError(validateCustomerName(value));
    }else if(name === 'customerPhone'){
      setCustomerPhone(value);
      setCustomerPhoneError(validateCustomerPhone(value));
    }else if(name === 'photoCount'){
      setPhotoCount(value);
      setPhotoCountError(validatePhotoCount(value));
    }
  };

  const formValidate = (event) => {
    event.preventDefault();
    let customerNameError = validateCustomerName(customerName)
    let customerPhoneError = validateCustomerPhone(customerPhone)
    let photoCountError = validatePhotoCount(photoCount)
    setCustomerNameError(customerNameError)
    setCustomerPhoneError(customerPhoneError)
    setPhotoCountError(photoCountError)

    if ( customerNameError || customerPhoneError || photoCountError) {
      return;
    }
    handleSubmit(event)
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} locale="zh-CN">
      <form onSubmit={formValidate} style={{ width: 600, margin: "0px auto"}}>
        <Box sx={{ p: 5, borderRadius: 1, margin: "0px auto" }}>
          <FormControl margin="normal" fullWidth>

          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("Customer Name")} *</FormLabel>
            <TextField
              size="small"
              placeholder={t("Customer Name")}
              name='customerName'
              value={customerName}
              error={!!customerNameError}
              helperText={customerNameError || undefined}
              onChange={handleInputChange}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("Photo Limit")} *</FormLabel>
            <TextField
              size="small"
              placeholder={t("Photo Limit")}
              name='photoCount'
              value={photoCount}
              error={!!photoCountError}
              helperText={photoCountError || undefined}
              onChange={handleInputChange}
            />
          </FormControl>


            <FormLabel>{t("Package Name")}{t("(Optional)")}</FormLabel>
            <Select
              labelId="packageName"
              value={packageId}
              name='packageId'
              size="small"
              onChange={handleInputChange}
            >
              {packages.map((item) => {
              return <MenuItem key={item.name + item.id} value={item.id}>{item.name}</MenuItem>
            })}
            </Select>
          </FormControl>

          

          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("Customer Phone")}{t("(Optional)")}</FormLabel>
            <TextField
              size="small"
              placeholder={t("Customer Phone")}
              name="customerPhone"
              value={customerPhone}
              error={!!customerPhoneError}
              helperText={customerPhoneError || undefined}
              onChange={handleInputChange}
            />
          </FormControl>

        

          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("Shoot Location")}{t("(Optional)")}</FormLabel>
            <TextField
              size="small"
              placeholder={t("Shoot Location")}
              value={shootLocation}
              onChange={e => setShootLocation(e.target.value)}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("Remark")}{t("(Optional)")}</FormLabel>
            <TextField
              size="small"
              placeholder={t("Remark")}
              value={remark}
              onChange={e => setRemark(e.target.value)}
              multiline
              rows={4}
            />
          </FormControl>

          <FormControl margin="normal" fullWidth>
            <FormLabel>{t("Shoot Time")}{t("(Optional)")}</FormLabel>
            <DatePicker
              size="small"
              placeholder={t("Shoot Time")}
              value={shootTime}
              onChange={newDate => setShootTime(newDate)}
              slotProps={{ textField: { variant: 'outlined' } }}
            />
          </FormControl>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
           
            <Button variant="contained" color="primary" type="submit">
              {t("Submit")}
            </Button>
          </Box>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default OrderForm;

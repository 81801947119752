import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Dialog, IconButton, Slide, Checkbox, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import theme from '../theme';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';

import defaultPicture from '../assets/default.jpg'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImagePreviewDialog = inject('snackbarStore', 'photoStore')(observer(({ photoStore, handlePictureSelect, snackbarStore }) => {

  const { currentPhoto } = photoStore;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [animation, setAnimation] = useState(false);

  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    photoStore.setCurrentPhoto(null)
    setAnimation(false)
  };

  const handleNext = useCallback(() => {
    const lastIndex = photoStore.filtedPhotos.length;
      if( currentIndex < lastIndex - 1  ){
        setCurrentIndex(currentIndex + 1);
      }else{
        snackbarStore.showSnackbar(t('Last picture'),'warning')
      }
  },[ currentIndex,snackbarStore,t]);

  const handlePrev =useCallback(() => {
    if( currentIndex > 0  ){
      setCurrentIndex(currentIndex - 1);
    }else{
      snackbarStore.showSnackbar(t('Already the first picture'),'warning')
    }
  },[currentIndex,snackbarStore,t]);

  useEffect(() => {
    function handleKeyPress(event) {
      switch (event.key) {
        case 'ArrowLeft':
          handlePrev()
          break;
        case 'ArrowRight':
          handleNext()
          break;
        default:
          // 处理其他按键
          break;
      }
      event.preventDefault();
      event.stopPropagation();
    }
    if (currentPhoto) {
      // 绑定键盘按下事件监听器
      window.addEventListener('keydown', handleKeyPress);
    }

    // 组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handlePrev, handleNext, currentPhoto]);


  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const imageStyle = {
    height: "60px",
    margin: "10px",
    border: "3px solid #fff",
    cursor: "pointer",
  }

  useEffect(() => {
    if (currentPhoto == null) return;
    setCurrentIndex(photoStore.filtedPhotos.findIndex((photo) => photo.id === currentPhoto.id))
  }, [currentPhoto])


  if (currentPhoto == null) return (<Box></Box>)

  return (
    <Dialog
      fullScreen
      open={!!currentPhoto}
      TransitionComponent={Transition}
      keepMounted
      disablebackdropclick="true"
      maxWidth={false}
      onLoad={() => setAnimation(true)}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          height: "100%",
          display: "flex"
        },
      }}
    >

      <Box sx={{ height: "60px", position: "absolute", zIndex: 10, left: 0, top: 0, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
        <IconButton onClick={handleClose}
          sx={{
            ml: 2,
            color: '#fff',
            backgroundColor: theme.palette.secondary.light,
            '&&:hover': {
              backgroundColor: theme.palette.secondary.main, // 自定义hover颜色
            }
          }}>
          <Close />
        </IconButton>
      </Box>

      <Box
        {...handlers}
        sx={{ flex: 1, display: "flex", flexDirection: "row", width: "100%", paddingTop: "60px" }}>
        <Box sx={{
          display: {
            xs: "none",
            md: "flex"
          },
          alignItems: "center", justifyContent: "center", width: 100
        }}>
          <IconButton onClick={handlePrev}
            sx={{
              color: '#fff',
              backgroundColor: theme.palette.primary.light,
              '&&:hover': {
                backgroundColor: `${theme.palette.primary.main}`, // 自定义hover颜色
              }
            }} >
            <NavigateBefore />
          </IconButton>
        </Box>

        <div
          style={{ position: "relative", width: "100%", height: "calc(100vh - 120px)", overflow: "hidden" }}
        >
          <div
            style={{ height: "100%", width: "100%", transition: animation ? "transform 0.3s ease-in-out" : "", transform: `translateX(-${currentIndex * 100}%)` }}
          >
            {photoStore.filtedPhotos?.map((photo, index) => {
              return <Box  key={index}  
              sx={{
                display:'flex',
                flex:1,
                flexDirection: 'column',
                alignItems:"center",
                justifyContent:"center",
                position: "absolute",
                left:0,
                top:0,
                height:"100%",
                width:"100%",
                transform: `translate(${index * 100}% ,0)`,
                backgroundImage: `url(${currentIndex<=index+1 && currentIndex>=index-1?photo?.publicUrl:defaultPicture})`,
                backgroundSize: 'contain', // 确保图片覆盖整个元素
                backgroundPosition: 'center', // 图片居中
                backgroundRepeat: "no-repeat",
              }}>
              <Typography 
                variant='h6'
                sx={{
                  color: '#fff',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  padding: '5px',
                  textAlign: 'center',
                }}  
              >{photo?.fileName}</Typography>
            </Box>})}
          </div>
        </div>

        <Box sx={{
          display: {
            xs: "none",
            md: "flex"
          }, alignItems: "center", justifyContent: "center", width: 100
        }}>
          <IconButton onClick={handleNext} color='primary'
            sx={{
              color: '#fff',
              backgroundColor: theme.palette.primary.light,
              '&&:hover': {
                backgroundColor: `${theme.palette.primary.main}`, // 自定义hover颜色
              }
            }}
          >
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>

    </Dialog>
  );
}));

export default ImagePreviewDialog;

import { Box, Button } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React from 'react';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

async function processPhotosInBatches(photos, batchsize, negativeOutputFolderHandle, retouchingOutputFolderHandle,onDownloadPicture) {
    for (let i = 0; i < photos.length; i += batchsize) {
        const batch = photos.slice(i, i + batchsize);
        await Promise.all(batch.map(async (photo) => {
            try {
                const response = await axios.get(photo.publicUrlOriginal,{
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onDownloadPicture(photo.id,percentCompleted)
                     },
                });
                const blob = response.data;
                const folderHandle = photo.type === 'negative' ? negativeOutputFolderHandle : retouchingOutputFolderHandle;
                const fileHandle = await folderHandle.getFileHandle(photo.originalFileName.trim(), { create: true });
                const writable = await fileHandle.createWritable();
                await writable.write(blob);
                await writable.close();
            } catch (err) {
                console.error(`Failed to save ${photo.originalFileName}:`, err);
            }
        }));
    }
}

// 设置批次大小
const BATCH_SIZE = 10;

const DownloadButton = inject('snackbarStore')(observer(({snackbarStore, photos ,onError,onDownloadPicture }) => {

    const {t} = useTranslation()


    const handleToggleDownload = async () => {
        try {
            const directoryHandle = await window.showDirectoryPicker();

            let nagativeOutputFolderHandle;
            let retouchingOutputFolderHandle;
            try {
                nagativeOutputFolderHandle = await directoryHandle.getDirectoryHandle('negative');
                retouchingOutputFolderHandle = await directoryHandle.getDirectoryHandle('retouching');
            } catch {
                nagativeOutputFolderHandle = await directoryHandle.getDirectoryHandle('negative', { create: true });
                retouchingOutputFolderHandle = await directoryHandle.getDirectoryHandle('retouching', { create: true });
            }
            await processPhotosInBatches(photos, BATCH_SIZE, nagativeOutputFolderHandle, retouchingOutputFolderHandle,onDownloadPicture);
            snackbarStore.showSnackbar(t(`Download completed`), "success")
        }catch(error){
            if (error.code !== 20) {
                onError()
                snackbarStore.showSnackbar(t(`Something wrong with write file ,Please use pack file button`), "warning")
                return
            }
            else {
                snackbarStore.showSnackbar(t(`Open local directory canceled`), "warning")
            }
        }
    };


    return (
        <Box sx={{ display: "flex",  ml: 2 ,flexDirection: "row", alignItems: "center", justifyContent: "center"}}>
            <Button  variant="contained" size='small' onClick={handleToggleDownload}>
                {t('Select a directory to save')}
            </Button>
        </Box>
    );
}));


export default DownloadButton;

import React, { useEffect, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { Dialog, Box, IconButton, Typography,DialogContent, Button } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { set } from 'mobx';
import http from '../http';

const SpaceAlert = inject('userStore', 'chargeStore', 'snackbarStore')(observer(({ userStore, chargeStore, snackbarStore }) => {

    const { t } = useTranslation();
    const [open, setOpen] = useState(false);

    useEffect(() => {

        if( !userStore.spaceLimit || !userStore.spaceUsed){
            return
        }

        if (userStore.spaceUsed >= userStore.spaceLimit) {
            setOpen(true)
        }

    }, [userStore.spaceUsed, userStore.spaceLimit, chargeStore, snackbarStore, t])

    const requestSpace = () => {
        http.post('/user/addExtraSpace').then((res) => {
            if( res.status == 200 ) {
                userStore.addExtraSpace(5 * 1024 * 1024 * 1024)
                snackbarStore.showSnackbar(t('extra space added'))
                setOpen(false)
            }
        })
    }


    return <Dialog
        open={open}
        fullWidth={true}
        maxWidth="sm"
    >
        <DialogContent>
            <Typography variant="h6">{t('Out of space and you can:')}</Typography>
            <Box sx={{mt:3,display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                <Box sx={{flex:1}}>
                    <Typography variant="body1">{t('Upgrade to a higher plan')}</Typography>
                    <Button onClick={() => { 
                        chargeStore.openChargeDialog() 
                    }}>{t("Upgrade")}</Button>
                </Box>
                {userStore.extraSpace ==0 &&<Typography variant="body1">{t('Or')}</Typography>}
                {userStore.extraSpace ==0 &&<Box sx={{flex:1,display:"flex",flexDirection:"column", justifyContent:"center",alignItems:"center"}}>
                    <Typography variant="body1">{t('You have 5G spaces to get')}</Typography>
                    <Button onClick={requestSpace}>{t('Request more space')}</Button>
                </Box>}
            </Box>
        </DialogContent>
    </Dialog>
}))

export default SpaceAlert;

import dayjs from 'dayjs';
import i18n from 'i18next';
import { ISOFile, BoxParser, MP4BoxStream } from 'mp4box';

function isWeChatBrowser() {
  const userAgent = navigator.userAgent.toLowerCase();
  return userAgent.includes('micromessenger');
}


function translate(key,prams={}) {
  return i18n.t(key,prams);
}

function getLevelName(level) {
  switch (level) {
    case 1:
      return 'Intermediate Member';
    case 2:
      return 'Advanced Member';
    default:
      return 'Basic Member';
  }
}

function generateColor(phoneNumber) {
  let hash = 0;
  for (let i = 0; i < phoneNumber.length; i++) {
    hash = phoneNumber.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `hsl(${hash % 360}, 75%, 60%)`;
  return color;
}


function isNumber(value) {
  return /^-?\d+(\.\d+)?$/.test(value);
}

function getShareInfo(expiredTime) {
  if (!expiredTime) return 'not shared'
  let diffDays = dayjs(expiredTime).diff(dayjs(), 'day')
  if( diffDays >0 ){
    return translate("{{day}} days left", { day: diffDays })
  }else{
    return translate(`Expired`)
  }
}

function getSpaceUsedInUnit(spaceUsed) {
  if (spaceUsed < 1024) {
    return `${spaceUsed} B`;
  } else if (spaceUsed < 1024 * 1024) {
    return `${(spaceUsed / 1024).toFixed(2)} KB`;
  } else if (spaceUsed < 1024 * 1024 * 1024) {
    return `${(spaceUsed / 1024 / 1024).toFixed(2)} MB`;
  } else {
    return `${(spaceUsed / 1024 / 1024 / 1024).toFixed(2)} GB`;
  }
}

function readFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      resolve(event.target.result);
    };

    reader.onerror = (error) => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(file);
  });
}
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');

function resizeImage(src, maxDimension, openWatermark, watermarkStyle, Watermark, watermarkAlpha, watermarkPosition, watermarkFontSize, getOrigin = false) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = async () => {
      requestAnimationFrame(async () => {
        try {
          const result = await processImage(
            image, 
            maxDimension, 
            openWatermark, 
            watermarkStyle, 
            Watermark, 
            watermarkAlpha, 
            watermarkPosition, 
            watermarkFontSize, 
            getOrigin
          );
          resolve(result);
        } catch (error) {
          reject(error);
        }
      });
    };
    image.onerror = reject;
  });
}

async function toBlobPromise(canvas, type = 'image/jpeg', quality = 0.90) {
  return new Promise((resolve) => {
    canvas.toBlob(resolve, type, quality);
  });
}
async function processImage(image, maxDimension, openWatermark, watermarkStyle, Watermark, watermarkAlpha, watermarkPosition, watermarkFontSize, getOrigin) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = 'high';
  
  let blobOrigin = null;
  if (getOrigin) {
    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0, image.width, image.height);
    blobOrigin = await toBlobPromise(canvas);
  }

  let resizeWidth, resizeHeight;
  if (image.width > image.height) {
    resizeWidth = Math.min(maxDimension, image.width);
    const scaleFactor = resizeWidth / image.width;
    resizeHeight = scaleFactor * image.height;
  } else {
    resizeHeight = Math.min(maxDimension, image.height);
    const scaleFactor = resizeHeight / image.height;
    resizeWidth = scaleFactor * image.width;
  }

  canvas.width = resizeWidth;
  canvas.height = resizeHeight;
  ctx.drawImage(image, 0, 0, resizeWidth, resizeHeight);

  if (openWatermark && Watermark) {
    let fontSize = watermarkFontSize / 600 * canvas.height;
    drawPositionedWatermarkWithShadow(canvas, watermarkStyle, Watermark, watermarkAlpha, watermarkPosition, fontSize, 'white');
  }

  const blob = await toBlobPromise(canvas);

  canvas.width = 600;
  canvas.height = resizeHeight * 600 / resizeWidth;
  ctx.drawImage(image, 0, 0, 600, canvas.height);

  if (openWatermark && Watermark) {
    let fontSize = watermarkFontSize / 600 * canvas.height;
    drawPositionedWatermarkWithShadow(canvas, watermarkStyle, Watermark, watermarkAlpha, watermarkPosition, fontSize, 'white');
  }

  const blobSmall = await toBlobPromise(canvas);

  return {
    blob,
    blobSmall,
    blobOrigin
  };
}

function drawPositionedWatermarkWithShadow(canvas, watermarkStyle, text, watermarkAlpha, position, fontSize, textColor, shadowColor = 'rgba(0, 0, 0, 0.5)', shadowBlur = 10, shadowOffsetX = 3, shadowOffsetY = 3) {
  const ctx = canvas.getContext('2d');
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  fontSize = parseInt(fontSize);

  ctx.font = `${fontSize}px Arial`;
  ctx.fillStyle = textColor;
  ctx.globalAlpha = watermarkAlpha; // 设置水印透明度
  ctx.shadowColor = shadowColor;
  ctx.shadowBlur = shadowBlur;
  ctx.shadowOffsetX = shadowOffsetX;
  ctx.shadowOffsetY = shadowOffsetY;

  if (watermarkStyle == "single") {
    // 根据位置调整坐标并绘制单个水印
    let x, y;
    switch (position) {
      case 'top-left':
        x = fontSize;
        y = fontSize;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'top';
        break;
      case 'top-right':
        x = canvasWidth - fontSize * text.length / 2;
        y = fontSize;
        ctx.textAlign = 'right';
        ctx.textBaseline = 'top';
        break;
      case 'bottom-left':
        x = fontSize;
        y = canvasHeight - fontSize * 1.5;
        ctx.textAlign = 'left';
        ctx.textBaseline = 'bottom';
        break;
      case 'bottom-right':
        x = canvasWidth - fontSize * text.length / 2;
        y = canvasHeight - fontSize * 1.5;
        ctx.textAlign = 'right';
        ctx.textBaseline = 'bottom';
        break;
      case 'center':
      default:
        x = canvasWidth / 2;
        y = canvasHeight / 2;
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
    }
    ctx.fillText(text, x, y);
  } else if (watermarkStyle == "fill") {

    const textWidth = ctx.measureText(text).width;  // 测量文本宽度
    const spacing = 10;  // 设定固定间隔
    const diagonal = Math.sqrt(canvasWidth ** 2 + canvasHeight ** 2);
    const step = textWidth + spacing;  // 根据实际文本宽度计算步长

    for (let x = -diagonal; x < diagonal; x += step) {
      for (let y = -diagonal; y < diagonal; y += step) {
        ctx.save();
        // 旋转前平移到文本将要绘制的中心点
        ctx.translate(x + textWidth / 2, y + fontSize / 2);
        ctx.rotate(-45 * Math.PI / 180); // 旋转-45度
        // 绘制文本，因为已经旋转，所以调整绘制起点为文本中心
        ctx.fillText(text, -textWidth / 2, -fontSize / 2);
        ctx.restore();
      }
    }
  }

  // 恢复画布状态
  ctx.globalAlpha = 1.0;
  ctx.shadowColor = 'transparent';
}



function loadImage(imgSrc) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imgSrc;
    image.onload = () => {
      resolve(image)
    }
    image.onerror = () => {
      reject(new Error('Image loading failed'));
    };
  })
}

function countChineseAndEnglish(str) {
  let chineseCount = 0;
  let englishCount = 0;
  for (let i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) > 255) {
      chineseCount++; // 中文字符的Unicode值大于255
    } else {
      englishCount++;
    }
  }
  return { chineseCount, englishCount };
}

function getShortName(username) {
  let shortUsername = username.substr(0, 1)
  if (isNumber(username)) {
    username += ""
    shortUsername = username.substr(-4)
  }
  return shortUsername;
}

function toFixed(number) {
  return number.toFixed(2)
}

function getFileNameWithoutExtension(fileName) {
  return fileName.split('.').slice(0, -1).join('.').trim()
}

function inFileList(fileList, targetLocalFileName) {
  let result = fileList.filter(file => {
    let fileNameWithoutExtension = getFileNameWithoutExtension(file.fileName)
    return targetLocalFileName.includes(fileNameWithoutExtension)
  })
  return result.length > 0
}

function convertCuteToTexture(gl, cubeFileContent) {
  const lines = cubeFileContent.split('\n');
  let lutSize = 0;
  const table = [];

  for (let line of lines) {
    line = line.trim();

    if (line.startsWith('#')) continue; // 忽略注释

    if (line.toLowerCase().startsWith('lut_3d_size')) {
      lutSize = parseInt(line.split(/\s+/)[1]);
      continue;
    }

    if (lutSize > 0) {
      const parts = line.split(/\s+/);
      if (parts.length === 3) {
        table.push(parts.map(Number));
      }
    }
  }
  const flattenedTable = table.flat().map(value => value * 255); // 如果原始数据是0到1之间
  const textureData = new Uint8Array(flattenedTable);

  const lutTexture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_3D, lutTexture);

  gl.texImage3D(
    gl.TEXTURE_3D,
    0, // level of detail
    gl.RGB, // internal format
    lutSize,
    lutSize,
    lutSize,
    0, // border
    gl.RGB, // format
    gl.UNSIGNED_BYTE, // type
    textureData // pixel source
  );
  // 设置纹理参数
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_WRAP_R, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_3D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

  return lutTexture;
}

function createTextureFromBuffer(gl, buffer, width, height) {
  var texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);

  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, width, height, 0, gl.RGB, gl.UNSIGNED_BYTE, buffer);

  return texture;
}
function executeProcessingSteps(gl, srcTexture, srcTextureWidth, srcTextureHeight, steps) {
  // 定义顶点数据和纹理坐标
  var vertices = new Float32Array([
    -1.0, -1.0, 0.0, 0.0,  // 第一个三角形的第一个顶点
    1.0, -1.0, 1.0, 0.0,
    -1.0, 1.0, 0.0, 1.0,
    -1.0, 1.0, 0.0, 1.0,  // 第二个三角形
    1.0, -1.0, 1.0, 0.0,
    1.0, 1.0, 1.0, 1.0
  ]);
  var vertexCount = 6;  // 两个三角形，共6个顶点

  // 创建并绑定顶点缓冲区
  var vertexBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
  gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW);

  // 创建纹理和帧缓冲区
  var textures = [];
  var framebuffers = [];
  for (let i = 0; i < steps.length; i++) {
    textures[i] = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, textures[i]);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, srcTextureWidth, srcTextureHeight, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);

    framebuffers[i] = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffers[i]);
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, textures[i], 0);
  }

  // 执行处理步骤
  steps.forEach((step, index) => {
    gl.bindFramebuffer(gl.FRAMEBUFFER, index < steps.length - 1 ? framebuffers[index] : null);
    gl.bindTexture(gl.TEXTURE_2D, index === 0 ? srcTexture : textures[index - 1]);

    gl.useProgram(step.program);
    var positionLocation = gl.getAttribLocation(step.program, 'position');

    gl.enableVertexAttribArray(positionLocation);
    gl.vertexAttribPointer(positionLocation, 2, gl.FLOAT, false, 16, 0);


    let uniformLocation = gl.getUniformLocation(step.program, "uTexture");
    gl.activeTexture(gl.TEXTURE0);
    gl.uniform1i(uniformLocation, 0);

    Object.keys(step.uniforms).forEach(key => {
      let uniformLocation = gl.getUniformLocation(step.program, key);
      let value = step.uniforms[key];
      if (typeof value === 'object') {
        gl.activeTexture(gl.TEXTURE1);
        gl.bindTexture(gl.TEXTURE_3D, value);
        gl.uniform1i(uniformLocation, 1);
      } else if (typeof value === 'number') {
        gl.uniform1f(uniformLocation, value);
      }
    });

    gl.drawArrays(gl.TRIANGLES, 0, vertexCount);
    gl.disableVertexAttribArray(positionLocation);
  });

  gl.bindBuffer(gl.ARRAY_BUFFER, null);
  gl.bindFramebuffer(gl.FRAMEBUFFER, null);
}



function createShader(gl, type, source) {
  const shader = gl.createShader(type);
  gl.shaderSource(shader, source);
  gl.compileShader(shader);
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    console.error(gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
    return null;
  }
  return shader;
}


function createShaderProgram(gl, vertexShaderSource, fragmentShaderSource) {
  const vertexShader = createShader(gl, gl.VERTEX_SHADER, vertexShaderSource);
  const fragmentShader = createShader(gl, gl.FRAGMENT_SHADER, fragmentShaderSource);
  var program = gl.createProgram();
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);
  gl.linkProgram(program);
  if (!gl.getProgramParameter(program, gl.LINK_STATUS)) {
    console.error(gl.getProgramInfoLog(program));
    return;
  }
  return program;
}


// 将 Base64 编码字符串转换为 Blob 对象
function base64ToBlob(base64Data, contentType) {
  // Base64 字符串转换为二进制数据
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  // 创建 Blob 对象
  return new Blob([byteArray], { type: contentType });
}

const copyImageToClipboard = async  (base64Image)=> {
  try {
    const blob = base64ToBlob(base64Image, 'image/png');
    const clipboardItem = new ClipboardItem({ "image/png": blob });
    await navigator.clipboard.write([clipboardItem]);
    return true;
  } catch (error) {
    return false;
  }
}

function isMobile() {
  const userAgent = navigator.userAgent;

  // 检测移动设备的常见关键字
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}


class MyMp4File {
  VIDEO_TIMESCALE = 90000;
  AUDIO_TIMESCALE = 44100;


  isFirstVideoSample = true;
  chunkOffset = 40;
  file = null;
  createMP4File() {
    this.file = new ISOFile();
    const ftyp = this.file.add("ftyp")
      .set("major_brand", "mp42")
      .set("minor_version", 0)
      .set("compatible_brands", ["mp42", "isom"]);

    const free = this.file.add("free");

    const mdat = this.file.add("mdat");
    this.file.mdat = mdat;
    mdat.parts = [];
    mdat.write = function (stream) {
      this.size = this.parts.map(part => part.byteLength).reduce((a, b) => a + b, 0);
      this.writeHeader(stream);
      this.parts.forEach(part => {
        stream.writeUint8Array(part);
      });
    }

    const moov = this.file.add("moov");

    const mvhd = moov.add("mvhd")
      .set("timescale", 1000)
      .set("rate", 1 << 16)
      .set("creation_time", 0)
      .set("modification_time", 0)
      .set("duration", 0)
      .set("volume", 1)
      .set("matrix", [
        1 << 16, 0, 0, //
        0, 1 << 16, 0, //
        0, 0, 0x40000000
      ])
      .set("next_track_id", 1);
  }

  addTrak(config) {
    const isVideo = config.type === "video";
    const moov = this.file.moov;
    const trak = moov.add("trak");

    const id = moov.mvhd.next_track_id;
    moov.mvhd.next_track_id++;

    const tkhd = trak.add("tkhd")
      .set("flags",
        BoxParser.TKHD_FLAG_ENABLED |
        BoxParser.TKHD_FLAG_IN_MOVIE |
        BoxParser.TKHD_FLAG_IN_PREVIEW
      )
      .set("creation_time", 0)
      .set("modification_time", 0)
      .set("track_id", id)
      .set("duration", 0)
      .set("layer", 0)
      .set("alternate_group", 0)
      .set("volume", 1)
      .set("matrix", [
        1 << 16, 0, 0, //
        0, 1 << 16, 0, //
        0, 0, 0x40000000
      ])
      .set("width", (config.width || 0) << 16)
      .set("height", (config.height || 0) << 16);

    const mdia = trak.add("mdia");

    const mdhd = mdia.add("mdhd")
      .set("creation_time", 0)
      .set("modification_time", 0)
      .set("timescale", isVideo ? this.VIDEO_TIMESCALE : this.AUDIO_TIMESCALE)
      .set("duration", 0)
      .set("language", 21956)
      .set("languageString", "und");

    const hdlr = mdia.add("hdlr")
      .set("handler", isVideo ? "vide" : "soun")
      .set("name", "");

    const minf = mdia.add("minf");

    if (isVideo) {
      const vmhd = minf.add("vmhd")
        .set("graphicsmode", 0)
        .set("opcolor", [0, 0, 0]);
    } else {
      const smhd = minf.add("smhd")
        .set("flags", 1)
        .set("balance", 0);
    }

    const dinf = minf.add("dinf");
    const url = new BoxParser["url Box"]()
      .set("flags", 0x1);
    const dref = dinf.add("dref")
      .addEntry(url);

    var stbl = minf.add("stbl");

    if (isVideo) {
      const sample_description_entry = new BoxParser.avc1SampleEntry();
      sample_description_entry.data_reference_index = 1;
      sample_description_entry
        .set("width", config.width || 0)
        .set("height", config.height || 0)
        .set("horizresolution", 0x48 << 16)
        .set("vertresolution", 0x48 << 16)
        .set("frame_count", 1)
        .set("compressorname", "")
        .set("depth", 0x18);

      var avcC = new BoxParser.avcCBox();
      var stream = new MP4BoxStream(config.avcDecoderConfigRecord);
      avcC.parse(stream);
      sample_description_entry.addBox(avcC);

      const stsd = stbl.add("stsd")
        .addEntry(sample_description_entry);
    } else {
      var mp4a = new BoxParser.mp4aSampleEntry()
        .set("data_reference_index", 1)
        .set("channel_count", config.channels)
        .set("samplesize", 32)
        .set("samplerate", config.sampleRate);

      const stsd = stbl.add("stsd")
        .addEntry(mp4a);
    }

    const stts = stbl.add("stts")
      .set("sample_counts", [])
      .set("sample_deltas", []);

    if (isVideo) {
      const stss = stbl.add("stss")
        .set("sample_numbers", [])
    }

    const stsc = stbl.add("stsc")
      .set("first_chunk", [1])
      .set("samples_per_chunk", [1])
      .set("sample_description_index", [1]);

    const stsz = stbl.add("stsz")
      .set("sample_sizes", []);

    const stco = stbl.add("stco")
      .set("chunk_offsets", []);

    if (isVideo) {
      if (config.rotate === 90) {
        tkhd.set("matrix", [0, 0x10000, 0, -0x10000, 0, 0, 0, 0, 0x40000000])
      } else if (config.rotate === 180) {
        tkhd.set("matrix", [-0x10000, 0, 0, 0, -0x10000, 0, 0, 0, 0x40000000])
      } else if (config.rotate === 270) {
        tkhd.set("matrix", [0, -0x10000, 0, 0x10000, 0, 0, 0, 0, 0x40000000])
      } else {
        tkhd.set("matrix", [0x10000, 0, 0, 0, 0x10000, 0, 0, 0, 0x40000000])
      }
    }
    return trak;
  }

  addSample( trak, data, isSync, duration, addDuration) {
    const isVideo = trak.mdia.hdlr.handler === 'vide';

    if (this.isFirstVideoSample && isVideo) {
      this.isFirstVideoSample = false;
      const headerSize = 4 + data[0] << 24 + data[1] << 16 + data[2] << 8 + data[3];
      data = data.slice(headerSize);
    }

    this.file.mdat.parts.push(data);

    const scaledDuration = duration / (isVideo ? this.VIDEO_TIMESCALE : this.AUDIO_TIMESCALE) * 1000;
    trak.samples_duration += scaledDuration;
    trak.tkhd.duration += scaledDuration;
    trak.mdia.mdhd.duration += duration;
    if (addDuration) {
      this.file.moov.mvhd.duration += scaledDuration;
    }

    const stbl = trak.mdia.minf.stbl;

    let index = stbl.stts.sample_deltas.length - 1;
    if (stbl.stts.sample_deltas[index] !== duration) {
      stbl.stts.sample_deltas.push(duration);
      stbl.stts.sample_counts.push(1);
    } else {
      stbl.stts.sample_counts[index]++;
    }

    if (isVideo && isSync) {
      stbl.stss.sample_numbers.push(
        stbl.stts.sample_counts.reduce((a, b) => a + b)
      );
    }

    stbl.stco.chunk_offsets.push(this.chunkOffset);
    this.chunkOffset += data.byteLength;

    stbl.stsz.sample_sizes.push(data.byteLength);
    stbl.stsz.sample_count++;
  }
}



export {
  isMobile,
  generateColor,
  isNumber,
  getSpaceUsedInUnit,
  readFileAsDataURL,
  resizeImage,
  drawPositionedWatermarkWithShadow,
  loadImage,
  countChineseAndEnglish,
  getShareInfo,
  getShortName,
  getLevelName,
  translate,
  toFixed,
  getFileNameWithoutExtension,
  inFileList,
  createShaderProgram,
  executeProcessingSteps,
  convertCuteToTexture,
  createTextureFromBuffer,
  base64ToBlob,
  copyImageToClipboard,
  isWeChatBrowser,
  MyMp4File,
};
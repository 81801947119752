import axios from 'axios';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { v4 } from 'uuid';
import http from '../http';
import { userStore } from './UserStore';
import {readFileAsDataURL,resizeImage} from '../util'

class PictureStore {
  pictures = []; // 保存文件信息
  currentPic = null;
  filter = "all"
  photoLimitation = 0
  cover = null
  albumClaimed = false
  multiMode = false
  multiSelections = []


  constructor() {
    makeObservable(this, {
      photoLimitation: observable,
      cover:observable,
      filter: observable,
      pictures: observable,
      currentPic: observable,
      albumClaimed: observable,
      multiMode:observable,
      multiSelections:observable,
      setCurrentPic: action,
      addPicture: action,
      setPictures: action,
      selectedPictures: computed,
      unSelectedPictures: computed,
      setPhotoLimitation:action,
      previewPictures: computed,
      setPreviewPicturesFilter:action,
    });
  }

  setPhotoLimitation(limitation){
    this.photoLimitation = limitation
  }

  get previewPictures() {
    switch(this.filter){
      case "all":
        return this.pictures
      case "selected":
        return this.selectedPictures
      case "unselected":
        return this.unSelectedPictures
      default:
        return this.pictures
    }
  }

  get selectedPictures() {
    return this.pictures.filter(f => f.claimed);
  }

  get unSelectedPictures() {
    return this.pictures.filter(f => !f.claimed);
  }

  setPreviewPicturesFilter(filter) {
    this.filter = filter
  }



  setPictures(pictures) {
    this.pictures = pictures
  }

  async addPicture(fileUrl,fileSize,uuid,originalFileName,fileType,albumId,
    openWatermark,
    watermarkStyle,
    watermark,
    watermarkAlpha,
    watermarkPosition,
    watermarkFontSize
  ) {
    let fileName = originalFileName.replace(/\.[^/.]+$/, ".jpg").trim();
    let existPicture = this.pictures.find(f => f.originalFileName === originalFileName)
    if(existPicture){
      return "exist"
    }
    this.pictures.unshift({
      id: uuid,
      progress: -1,
    });
    const {blob,blobSmall} = await resizeImage(fileUrl,
      userStore.maxDimension,
      openWatermark,
      watermarkStyle,
      watermark,
      watermarkAlpha,
      watermarkPosition,
      watermarkFontSize)
    return await this.uploadToServer(uuid,originalFileName,fileName,albumId,fileType,blob,blobSmall,fileSize)
   
  }

  async uploadToServer(uuid,originalFileName,fileName,albumId,fileType,blob,blobSmall,originalFileSize){
    let fileSize = blob.size;
    let fileSizeSmall = blobSmall.size;
    const response = await http.post('/user/picture/addToAlbum', {
      originalFileName:originalFileName, 
      originalFileSize: originalFileSize,
      fileName: fileName, 
      albumId: albumId,
      fileSize: fileSize,
      fileSizeSmall:fileSizeSmall
     });
    const {ifExist, url, picture,urlSmall } = response.data

    if(ifExist){
      this.pictures = this.pictures.filter(f => f.id !== uuid)
      return "exist"
    }

    const index = this.pictures.findIndex(f => f.id === uuid);
    
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        this.updateProgress(uuid, percentCompleted);
      },
      headers: {
        'Content-Type': fileType
      }
    };

    await axios.put(urlSmall, blobSmall, {
      headers: {
        'Content-Type': fileType
      }
    });

    await axios.put(url, blob, config);

    if (index !== -1) {
      this.pictures[index] = picture
    }

    return "success"
  }

  updateProgress(uuid, progress) {
    const picture = this.pictures.find(f => f.id === uuid);
    if (picture) {
      picture.progress = progress;
    }
  }

  setCurrentPic(picture) {
    this.currentPic = picture;
  }

  setCover(pictureId) {
    this.cover = pictureId
  }

  setAlbumClaimed(claimed){
    this.albumClaimed = claimed
  }

  setMultiMode(mode){
    this.multiMode = mode
    if(!mode){
      this.multiSelections = []
    }
  }

  addMutiSelect(pictureId){
    this.multiSelections.push(pictureId)
  }

  removeMutiSelect(pictureId){
    this.multiSelections = this.multiSelections.filter(item => item !== pictureId)
  }

  async deletePictrus(orderId, pictureIds){
    const response = await http.post('/user/picture/delete', { orderId:orderId ,pictureIds: pictureIds });
    if(response.data.success){
      pictureIds.forEach(pictureId => {
        this.pictures = this.pictures.filter(f => f.id !== pictureId)
      });
      this.multiSelections = []
    }
   
  }
  reset(){
    this.pictures = []
    this.currentPic = null
    this.filter = "all"
    this.photoLimitation = 0
    this.cover = null
    this.albumClaimed = false
    this.multiMode = false
    this.multiSelections = []
  }
}

const pictureStore = new PictureStore();

export { pictureStore };

import { Box, Button, FormControl, FormLabel, TextField, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import withAuth from '../../HOC/withAuth';
import http from '../../http';
import LanguageSwitcher from '../../components/LanguageSwitcher';
import Subscription from '../../components/Subscription';

import { passwordRegex } from '../../const';

const UserInfoPage = inject("userStore", 'snackbarStore')(observer(({ userStore, snackbarStore }) => {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [username, setUsername] = useState('');
  const [userNameError, setUserNameError] = useState(false);


  const { t } = useTranslation();

  const handleLogout = () => {
    userStore.logout();
  };

  const validatePassword = (password) => {
    if (!password) {
      return t("password can not be empty")
    }
    if (!passwordRegex.test(password)) {
      return t("_Password Rule")
    }
    return '';
  }

  const validateConfirmPassword = (confirmPassword) => {
    if (!confirmPassword) {
      return t("password can not be empty")
    }
    if (confirmPassword !== password) {
      return t("Passwords do not match!")
    }
    return '';
  }


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'confirmPassword') {
      setConfirmPassword(value);
      setConfirmPasswordError(validateConfirmPassword(value));
    } else if (name === 'password') {
      setPassword(value);
      setPasswordError(validatePassword(value));
    } else if (name == "username") {
      setUsername(value);
      setUserNameError(validateUserName(value));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let passwordError = validatePassword(password);
    let confirmPasswordError = validateConfirmPassword(confirmPassword);
    setPasswordError(passwordError);
    setConfirmPasswordError(confirmPasswordError);
    if (!passwordError && !confirmPasswordError) {
      http.post('/user/changePassword', { password })
        .then(response => {
          snackbarStore.showSnackbar(t("Password changed successfully"))
          userStore.logout();
        })
        .catch(error => { });
    }
  };

  const validateUserName = (username) => {
    if (!username) {
      return t("Username can not be empty")
    }
    if (username.length < 1) {
      return t("Username must be at least 1 characters long")
    }
    if (username.length > 20) {
      return t("Username must be at most 20 characters long")
    }
    return '';
  }

  const handleUserNameSubmit = (event) => {
    event.preventDefault();
    let userNameError = validateUserName(username);
    setUserNameError(userNameError);
    if (!userNameError) {
      http.post('/user/changeUsername', { username })
        .then(response => {
          snackbarStore.showSnackbar(t("UserName updated!"))
          userStore.username = username;
        })
        .catch(error => { });
    }
  }

  useEffect(() => {
    setUsername(userStore.username);
  }, [userStore.username]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", p:2 }} >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Typography variant="h3">
            {t("User Info")}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, flexWrap: 'wrap', borderRadius: 1 }}>
          <Box sx={{ width: 600, margin: "0px auto",mb:4 }}>
            <Subscription />
          </Box>
          <form onSubmit={handleUserNameSubmit} style={{ width: 600, margin: "0px auto", }}>
            <Box sx={{ backgroundColor: "#ffffff", borderRadius: 1 }}>
              <FormControl margin="normal" fullWidth>
                <FormLabel>{t("Username")}</FormLabel>
                <TextField
                  size="small"
                  placeholder={t("Username")}
                  type="Username"
                  name="username"
                  value={username}
                  onChange={handleInputChange}
                  error={!!username}
                  helperText={userNameError ? t("_Username Rule") : ""}
                />
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: 2,
                }}
              >
                <Button variant="contained" color="primary" type="submit">
                  {t("Change Name")}
                </Button>
              </Box>
            </Box>
          </form>
          <form onSubmit={handleSubmit} style={{ width: 600, margin: "0px auto", }}>
            <Box sx={{ backgroundColor: "#ffffff", borderRadius: 1 }}>
              <FormControl margin="normal" fullWidth>
                <FormLabel>{t("Password")}</FormLabel>
                <TextField
                  size="small"
                  placeholder={t("Password")}
                  type="password"
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  error={!!passwordError}
                  helperText={passwordError ? t("_Password Rule") : ""}
                />
              </FormControl>
              <FormControl margin="normal" fullWidth>
                <FormLabel>{t("Confirm Password")}</FormLabel>
                <TextField
                  size="small"
                  placeholder={t("Confirm Password")}
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleInputChange}
                  error={!!confirmPasswordError}
                  helperText={confirmPasswordError ? t("Passwords do not match!") : ""}
                />
              </FormControl>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  mt: 2,
                }}
              >
                <Button variant="contained" color="primary" type="submit">
                  {t("Change Password")}
                </Button>
              </Box>
            </Box>
          </form>

        </Box>

        <Button sx={{ width: 600, margin: "20px auto" }} variant="contained" color="error" fullWidth onClick={handleLogout}>退出</Button>
      </Box>
    </Box>
  );
}));

export default withAuth(UserInfoPage);

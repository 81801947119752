import React, { useRef, forwardRef,useEffect,useImperativeHandle } from 'react';

import defaultImage from '../assets/preview.jpg';

import { drawPositionedWatermarkWithShadow,loadImage } from '../util';

const WatermarkPreview = forwardRef(({width,height}, ref) => {
    const canvasRef = useRef(null);
    const imageRef = useRef(null);  // 创建一个ref来存储图像

    let w = width || 900;
    let h = height || 600;

    const loadImageOnce = async () => {
        if (!imageRef.current) {  // 只有在图像未被加载时才加载
            imageRef.current = await loadImage(defaultImage);
        }
    }

    const drawWatermark = async (open ,watermarkStyle, text, watermarkAlpha, fontSize, position) => {
        await loadImageOnce();  // 确保图像加载完毕
        if (canvasRef.current && imageRef.current) {
            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(imageRef.current, 0, 0, canvas.width, canvas.height);
            if (text?.length > 0 && open) {
                drawPositionedWatermarkWithShadow(canvas,watermarkStyle, text,watermarkAlpha, position, fontSize, 'white');
               
            }
        }
    }

    useImperativeHandle(ref, () => ({
        drawWatermark,
    }));

    // 当组件挂载时加载图像
    useEffect(() => {
        loadImageOnce();
    }, []);

    return (
        <canvas ref={canvasRef} width={w} height={h} />
    );
});

export default React.memo(WatermarkPreview);

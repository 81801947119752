import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import theme from '../theme';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import HorizaontalIconButton from './HorizontalIconButton';

import FavoriteIcon from '@mui/icons-material/Favorite';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import http from '../http';
import IconButtonWithText from './IconButtonWithText';
import DeleteIcon from '@mui/icons-material/Delete';

const HoverHandles = observer(({ orderId,albumId, picture, copyToDelivery, pictureStore ,photoStore }) => {
  const { t } = useTranslation();
  const handlePrviewClick = () => {
    pictureStore.setCurrentPic(picture);
    return
  };
  const handleSetAsCover = () => {
    http.put(`/user/albums/${albumId}/setAsCover`, {
      cover: picture.id
    }).then(response => {
      pictureStore.setCover(picture.id);
    }).catch(error => {});
  }
  return (
    <Box sx={{
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0,0,0,0.5)",
      zIndex: 100
    }}>
      <IconButtonWithText text={t("Preview")} icon={<PhotoLibraryIcon sx={{fontSize:"1.2rem"}} />} onClick={handlePrviewClick} />
      
      <Box sx={{
            position: "absolute",
            right: 5,
            top: 5,
          }}>
            <Button
              variant="contained"
              color='secondary'
              size='small'
              onClick={handleSetAsCover} >
                {t("Set as cover")}
            </Button>

            <IconButton
              sx={{
                backgroundColor: "transparent",
                p: 0.5,
                ml:1
              }}
              onClick={() => {
                pictureStore.deletePictrus(orderId,[picture.id])
                if(copyToDelivery){
                  photoStore.deletePhotoWithName(orderId,[picture.originalFileName])
                }
              }} >
              <DeleteIcon sx={{ color: "white", fontSize: "1.2rem" }} />
            </IconButton>
          </Box>
    
    </Box>
  )
})


const MultiHandles = observer(({ picture, pictureStore }) => {
  const { t } = useTranslation();


  const isSelected = useMemo(() => {
    return pictureStore?.multiSelections.includes(picture.id)
  })

  const handleSelect = (event) => {
    let { target } = event
    if (isSelected) {
      pictureStore.removeMutiSelect(picture.id)
    } else {
      pictureStore.addMutiSelect(picture.id)
    }
    return;
  }

  return (
    <Box sx={{
      position: "absolute",
      top: "0px",
      left: "0px",
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: isSelected ? "rgba(255,255,255,0.3)" : "transparent",
      cursor: "pointer"
    }}
      onClick={handleSelect}
    >
      {isSelected ? <CheckCircleIcon
        color='primary'
        sx={{
          position: "absolute",
          right: "2px",
          bottom: "2px",
          p: 0,
          borderRadius: "50%", backgroundColor: "white"
        }}/> : <RadioButtonUncheckedIcon
        color='primary'
        sx={{
          position: "absolute",
          right: "2px",
          bottom: "2px",
          p: 0,
          borderRadius: "50%", backgroundColor: "white"
        }} />}
    </Box>
  )
})


const ImageGallery = observer(({ orderId, copyToDelivery, albumId, picture, multiMode, isCover, pictureStore,photoStore, albumClaimed, colnumnNum }) => {

  //const theme = useTheme();
  // const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  // const isSm = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const { t } = useTranslation();



  const [isHovered, setIsHovered] = useState(false);

  return (
    <Grid item sm={colnumnNum}  >
      <Box
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: theme.palette.grey[300],
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
          height: 0,
          paddingTop: '66.66%',
          backgroundColor: theme.palette.grey[50],
          backgroundImage: `url(${picture.publicUrlSmall})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: 'contain', // 确保图片覆盖整个元素
          backgroundPosition: 'center' // 图片居中
        }}
      >
        {picture.progress >= 0 && picture.progress < 100 && <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: picture.progress >= 0 && picture.progress < 100 ? `${100 - picture.progress}%` : 0,
          background: "rgba(0, 0, 0, 0.3)"
        }} />}

        {picture.progress >= 0 && picture.progress < 100 && <div style={{
          position: 'absolute',
          top: "50%",
          left: "50%",
          width: "120px",
          height: "60px",
          fontSize: "60px",
          lineHeight: "60px",
          marginLeft: "-60px",
          marginTop: "-30px",
          textAlign: "center",
          color: "white"
        }}>{picture.progress}%</div>}

        {/* delete */}
        {!multiMode && isHovered && <HoverHandles 
          orderId={orderId} 
          copyToDelivery={copyToDelivery}
          albumId={albumId}
          picture={picture} 
          pictureStore={pictureStore}
          photoStore={photoStore}
        />}

        {/* multi opration */}
        {multiMode && <MultiHandles picture={picture} pictureStore={pictureStore} />}

        {/* select picture */}
        {albumClaimed && picture.claimed && <Box sx={{
          color: theme.palette.primary.main,
          fontSize: "1rem",
          p: 0.5,
          position: 'absolute',
          right: "0px",
          top: "0px"
        }}  ><FavoriteIcon /></Box>}

        {/* cover */}
        {isCover && <Box sx={{
          position: "absolute",
          left: "-20px",
          top: "5px",
          width: "80px",
          textAlign: "center",
          color: 'white',
          backgroundColor: theme.palette.primary.main,
          fontSize: "0.75rem",
          rotate: "-45deg",
          zIndex: 10,
          p: 1,
        }}
        > {t("Cover")} </Box>}

      </Box>
      <Box sx={{
        display: {
          xs: 'flex',
          sm: 'flex',
        }, justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        borderTop: 0
      }} >
        <Typography variant='body1'>
              {picture?.originalFileName?picture?.originalFileName:picture.fileName}
        </Typography>
      </Box>
    </Grid>
  );
})

export { ImageGallery };

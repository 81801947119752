// Layout.js
import React from 'react';
import { Routes, Route, Link, useLocation } from 'react-router-dom';
import { alpha, borderColor, darken, lighten } from '@mui/system';
import theme from '../theme';

import UserInfoPage from './users/info';
import Orders from './orders/index';
import Packages from './packages/index'
import PackageCreate from './packages/create'
import PackageEdit from './packages/edit'
import OrdersCreate from './orders/create'
import OrderEdit from './orders/edit'
import { useTranslation } from 'react-i18next';
import DeleteConfirmationDialog from '../components/DeleteConfirmationDialog';
import SpaceUsage from '../components/SpaceUsage';
import Subscription from '../components/Subscription';
import ChargeDialog from '../components/ChargeDialog';
import SubscriptionCallback from './subscription/callback';
import SubscriptionIndex from './subscription/index';

import { List, ListItem, ListItemIcon, ListItemText, Box, IconButton, Container, Typography } from '@mui/material';
import logo from '../assets/logo.png';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { useNavigate, Navigate } from 'react-router-dom';
import ICP from '../components/ICP';
import GradingOutlinedIcon from '@mui/icons-material/GradingOutlined';
import BookOutlinedIcon from '@mui/icons-material/BookOutlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Support from './users/support';
import Setting from './users/setting';
import CustomAvatar from "../components/CustomAvatar"
import Logo from '../components/Logo';
import SettingsIcon from '@mui/icons-material/Settings';
import SubscriptionAlert from '../components/SubscriptionAlert';
import SpaceAlert from '../components/SpaceAlert';
import Level from '../components/Level';

import Step1 from './orders/steps/step1';
import Step2 from './orders/steps/step2';
import Step3 from './orders/steps/step3';
import Step4 from './orders/steps/step4';
import Step5 from './orders/steps/step5';
import Step6 from './orders/steps/step6';
import Step7 from './orders/steps/step7';

import Selections from './orders/Selections';



function LocationAwareListItem({ to, children, ...rest }) {
  const location = useLocation();
  const isActive = location.pathname.startsWith(to);
  const bgColor = alpha(theme.palette.primary.main, 0.1);
  const hoverColor = isActive ? bgColor : darken(theme.palette.secondary.main, "5%");
  return (
    <ListItem
      button
      component={Link}
      to={to}
      className={isActive ? "nav-item active" : "nav-item"}
      sx={{
        ":hover": {
          backgroundColor: hoverColor,
          transition: "background-color 0.2s",
        },
      }}
      {...rest}
    >
      {children}
    </ListItem>
  );
}

function Layout() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box className="flex-container">
      <Box className="top-section"  >
        <Logo sx={{
          pl: 2,
          pr: 2
        }} logo={logo} home="/user/orders" />

        <Box className="nav" >
          <LocationAwareListItem key="userOrders" to="/user/orders">
            <GradingOutlinedIcon fontSize="small" />
            <Typography>{t("Orders")}</Typography>
          </LocationAwareListItem>
          <LocationAwareListItem key="userPackages" to="/user/packages">
            <BookOutlinedIcon fontSize="small" />
            <Typography>{t("Packages")}</Typography>
          </LocationAwareListItem>
          <LocationAwareListItem key="userSetting" to="/user/setting">
              <SettingsIcon fontSize="small" />
              <Typography>{t("Setting")}</Typography>
          </LocationAwareListItem>
          <LocationAwareListItem key="userSupport" to="/user/support">
              <SupportAgentIcon fontSize="small" />
              <Typography>{t("Support")}</Typography>
          </LocationAwareListItem>
          <LocationAwareListItem key="userInfo" to="/user/info">
              <AccountCircleIcon fontSize="small" />
              <Typography>{t("My")}</Typography>
          </LocationAwareListItem>
        </Box>

        <Box sx={{ flex: 1 }}></Box>
        <Level />
        <CustomAvatar />

        <LanguageSwitcher sx={{ mr: 2 }} />
      </Box>
      <Box className="bottom-section">
        <Box className="main" >
          <Routes>
            <Route path="/user/info" element={<UserInfoPage />} />
            <Route path="/user/support" element={<Support />} />
            <Route path="/user/orders" element={<Orders />} />
            <Route path="/user/orders/create" element={<OrdersCreate />} />
            <Route path="/user/orders/:orderId/edit" element={<OrderEdit />} />
            <Route path="/user/orders/:orderId/step1" element={<Step1 />} />
            <Route path="/user/orders/:orderId/step2" element={<Step2 />} />
            <Route path="/user/orders/:orderId/step3" element={<Step3 />} />
            <Route path="/user/orders/:orderId/step4" element={<Step4 />} />
            <Route path="/user/orders/:orderId/step5" element={<Step5 />} />
            <Route path="/user/orders/:orderId/step6" element={<Step6 />} />
            <Route path="/user/orders/:orderId/step7" element={<Step7 />} />
            <Route path="/user/selections/:selectionId" element={<Selections />} />
            <Route path="/user/packages" element={<Packages />} />
            <Route path="/user/packages/create" element={<PackageCreate />} />
            <Route path="/user/packages/:packageId/edit" element={<PackageEdit />} />
            <Route path="/user/subscription/callback/alipay" element={<SubscriptionCallback />} />
            <Route path="/user/subscriptions" element={<SubscriptionIndex />} />
            <Route path="/user/setting" element={<Setting />} />
            <Route path="*" element={<Navigate to="/login" replace />} />
          </Routes>
        </Box>
      </Box>
      <DeleteConfirmationDialog />
      <ChargeDialog />
      <SubscriptionAlert />
      <SpaceAlert />
    </Box>

  )

}

export default Layout;

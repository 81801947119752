import { Alert, Box, Button, Dialog, Tab, Tabs, DialogTitle, DialogContent, DialogContentText,DialogActions, Typography, Grid, FormControl, FormLabel, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import CustomTabLabel from '../../components/CustomTabLabel';
import Empty from '../../components/Empty';
import { CustomerPhotoDialog } from './CustomerPhotoDialog';
import { CustomerImageGallery } from './CustomerImageGallery';
import { PhotoPasswordVrifyDialog } from './PhotoPasswordVrifyDialog';
import http from '../../http';
import DownloadButton from '../../components/DownloadButton';
import { isMobile,isWeChatBrowser } from '../../util';
import { set } from 'mobx';



function ConfirmDialog({ open, onConfirm, title, onClose }) {
    const { t } = useTranslation();

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{"确认操作"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {title}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t("Cancel")}</Button>
                <Button onClick={handleConfirm} autoFocus>
                    {t("Confirm")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const types = ["negative", "retouching"]
const OrderSelect = inject('snackbarStore', 'anonymousUserStore')(observer(({ anonymousUserStore, snackbarStore }) => {
    const { orderId } = useParams();
    const [order,setOrder] = useState({})
    const [searchParams] = useSearchParams();
    const pwd = searchParams.get('pwd');
    const [needpassword, setNeedpassword] = useState(true);
    const [expiredDay, setExpiredDay] = useState(0);
    const [tabValue, setTabValue] = useState(0);
    const [photos, setPhotos] = useState([])
    const [preview, setPreview] = useState(null);
    const [currentPicIndex, setCurrentPicIndex] = useState(0);
    const [uiIsMobile, setUiIsMobile] = useState(false)
    const [showGenerateDownloadLink, setShowGenerateDownloadLink] = useState(false);
    const [zipPublicUrl, setZipPublicUrl] = useState(null);
    const refreshOrderInfo = React.useRef(null);

    const { t } = useTranslation();

    const negativePhotos = useMemo(() => {
        return photos.filter(photos => photos.type === 'negative')
    }, [photos])

    const retouchingPhotos =  useMemo(() => {
        return photos.filter(photos => photos.type === 'retouching')
    }, [photos])

    const shownPhotos = useMemo(() => {
        switch (tabValue) {
            case 0:
                return retouchingPhotos
            case 1:
                return negativePhotos
            default:
                return negativePhotos
        }

    }, [negativePhotos, retouchingPhotos, tabValue])

    const fetchOrderData = useCallback(() => {
        if (anonymousUserStore.accessToken) {
            http.get(`/customer/order/${orderId}/photos`, {
                headers: {
                    "y-access-token": anonymousUserStore.accessToken
                }
            }).then(response => {
                setNeedpassword(false)
                setPhotos(response.data?.photos.map(photo => {
                    photo.progress = -1
                    return photo
                }))
                let orderData = response.data?.order
                setOrder(orderData)
                document.title = orderData?.Album.title;
                let expiredTime = new dayjs(orderData?.Album?.photoExpiredTime)
                let now = new dayjs()
                let diff = expiredTime.diff(now, 'day')
                setExpiredDay(diff)
                setZipPublicUrl(orderData.Album?.zipPublicUrl)
              
            }).catch(error => {
                // 处理错误的响应
                anonymousUserStore.empty()
            });
        }

    }, [orderId, anonymousUserStore]);

    useEffect(() => {
        if (needpassword) {
            return
        }
        anonymousUserStore.check()
        fetchOrderData();
    }, [fetchOrderData, anonymousUserStore, needpassword]);

    useEffect(() => {
        setUiIsMobile(isMobile())
    }, [])

    if (needpassword) {
        return <PhotoPasswordVrifyDialog open={needpassword} pwd={pwd} orderId={orderId} fetchOrderData={fetchOrderData} anonymousUserStore={anonymousUserStore} />
    }

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    }

    const handleSaveFileError = () => {
        setShowGenerateDownloadLink(true)
    }

    const copyToClipboard = async () => {
        try {
            // 使用 Clipboard API
            let totalText = window.location.href
            await navigator.clipboard.writeText(totalText);
            snackbarStore.showSnackbar(t('Text copied to clipboard'))
        } catch (err) {
            snackbarStore.showSnackbar(t('Failed to copy text.'), 'error')
        }
    };

    const handlePackImages = () => {
        if (anonymousUserStore.accessToken) {
            http.get(`/customer/order/${orderId}/generatePhotoLink`, {
                headers: {
                    "y-access-token": anonymousUserStore.accessToken
                }
            }).then(response => {
                setOrder(response.data)
                refreshOrderInfo.current = setInterval(() => {
                    http.get(`/customer/order/${orderId}/photos`,{
                        headers:{
                            "y-access-token": anonymousUserStore.accessToken
                        }
                    }).then(photoResponse => {
                        let orderData = photoResponse.data?.order
                        setOrder(orderData)
                        if (orderData.Album?.photoLinkAt && orderData.Album?.requestPhotoLinkTimes == orderData.Album?.generatedPhotoLinkTimes) {
                            clearInterval(refreshOrderInfo.current)
                            setZipPublicUrl(orderData.Album?.zipPublicUrl)
                        }
                    })
                },1000)
            }).catch(error => {
                clearInterval(refreshOrderInfo.current)
            });
        }
    }

    if(isWeChatBrowser()){
        return <Empty
            message={t("WeChat browser does not support downloading images, please open link in your brower.")}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems:"center", flexDirection:"row", mt: 2, width:400 }}>
                <FormControl sx={{flexGrow:1}} >
                    <TextField
                        placeholder={t("PhotoShared Link")}
                        value={window.location.href}
                        size="small"
                    />
                </FormControl>
                <Button  sx={{ml:1}} variant="contained" color="primary" onClick={copyToClipboard}>{t('Copy url to clipboard')}</Button>
            </Box>
        </Empty>
    }


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <Box sx={{display:"flex",flexDirection:"row",justifyContent:"space-between"}}>
                <Tabs value={tabValue} onChange={handleChange}>
                    <Tab label={<CustomTabLabel label={t("retouching")} num={retouchingPhotos.length} />} />
                    <Tab label={<CustomTabLabel label={t("negative")} num={negativePhotos.length} />} />
                </Tabs>
                <Box sx={{display:"flex",p:1,flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
                    {order.Album?.requestPhotoLinkTimes > order.Album?.generatedPhotoLinkTimes &&
                    <Box sx={{display:"flex",flexDirection:"column"}}>
                        <Typography variant='body2'>{t("Generating download link")}</Typography>
                    </Box>}
                    {zipPublicUrl?.length>0 && <Button href={zipPublicUrl}>{t("Download")}</Button>}
                    {( showGenerateDownloadLink && !zipPublicUrl && order.Album?.requestPhotoLinkTimes == order.Album?.generatedPhotoLinkTimes) && <Button variant="contained" color='secondary' size='small' onClick={handlePackImages}>
                        {t('Pack images')}
                    </Button>}
                    {!uiIsMobile && <DownloadButton
                        onError={handleSaveFileError}
                        photos={photos}
                        onDownloadPicture={(uuid,progress) => {
                            const photo = photos.find(f => f.id === uuid)
                            photo.progress = progress
                            setPhotos([...photos])
                        }}
                    />}
                </Box>

               
                
            </Box>
            <Alert severity="warning" sx={{ width: '100%' }}>
                <Typography variant='body1'>{t("The picture will only be available for the next {{day}} days. Please save your photo soon!", { day: expiredDay })}</Typography>
            </Alert>


            <Box sx={{
                paddingBottom: "50px",
                overflowY: "scroll",
                flex: 1,
                m: {
                    xs: 1,
                },
            }}>
                {shownPhotos?.length > 0 ? <Grid container spacing={1} >
                    {shownPhotos?.map((picture, index) => (
                        <CustomerImageGallery
                            handlePreview={() => {
                                setPreview(true)
                                setCurrentPicIndex(index)
                            }}
                            picture={picture}
                            key={picture.id}
                        />
                    ))}
                </Grid> : (<Empty message={t("No picture available.")} />)}

            </Box>

            <CustomerPhotoDialog
                open={preview}
                setOpen={setPreview}
                previewPictures={shownPhotos}
                currentIndex={currentPicIndex}
                setCurrentIndex={setCurrentPicIndex}
            />

        </Box>
    );
}));

export default OrderSelect;


import { MyMp4File } from '../util';


const maxQueueSize = 5;
export class MediaCodecHandler {
    constructor(videoProgress, audioProgress,done) {
        this.videoTrack = null;
        this.audioTrack = null;
        this.useWebcodec = false;
        this.targetBitrate = 1000;
        this.running = true;
        //count
        this.decodedFrameCount = 0;//h264解码出来的帧数 用于计算 keyframe
        this.addedToMp4VideoFrameCount = 0;//编码出来的帧数
        this.addedToMp4AudioFrameCount = 0;//编码出来的音频帧数

        this.shouldExcutedVideoFrameCount = 0;//需要处理的视频帧数
        this.shouldExcutedAudioFrameCount = 0;//需要处理的音频帧数
        this.videoProgress = videoProgress;
        this.audioProgress = audioProgress;
        this.done = done;
        this.totalAudioFrame = 0;
        this.totalVideoFrame = 0;
        this.totalLength = 0;

        //config
        this.targetFrameRate = 24;
        this.targetSampleRate = 44100;
        this.targetChannels = 1;
        this.outputFile = null;
        this.outputWidth = 1920;
        this.outputHeight = 1080;
        this.rotate = 0;
        this.skipFrames = 0;
        this.frameDuration = 0

        //queue
        this.h264Queue = [];
        this.pcmQueue = [];
        this.yuvQueue = [];

        this.unpackFilish = false;

        this.VIDEO_TIMESCALE = 90000;
        this.AUDIO_TIMESCALE = 44100;
     
        setTimeout(this.processQueue.bind(this), 100);
    }

    async processQueue() {
        if( !this.running ){
            this.h264Queue = [];
            this.pcmQueue = [];
            this.yuvQueue = [];
            this.shouldExcutedVideoFrameCount = 0;
            this.shouldExcutedAudioFrameCount = 0;
        }
        while (this.h264Queue.length > 0 && this.videoDecoder?.decodeQueueSize < maxQueueSize) {
            let chunk = this.h264Queue.shift();
            this.videoDecoder.decode(chunk);
            this.shouldExcutedVideoFrameCount--;
        }
    
        while (this.pcmQueue.length > 0 && this.audioEncoder?.encodeQueueSize < maxQueueSize) {
            let audioData = this.pcmQueue.shift();
            this.audioEncoder.encode(audioData);
            this.shouldExcutedAudioFrameCount--;
        }
    
        while (this.yuvQueue.length > 0 && this.videoEncoder?.encodeQueueSize < maxQueueSize) {
            let { frame, keyFrame } = this.yuvQueue.shift();
            this.videoEncoder.encode(frame, { keyFrame });
            frame.close();
            this.shouldExcutedVideoFrameCount--;
        }
    
        if (this.unpackFilish && this.shouldExcutedVideoFrameCount==0 &&  this.shouldExcutedAudioFrameCount ==0 ) {
            if(this.useWebcodec){
                await this.videoDecoder.flush();
            }
            await this.audioEncoder.flush();
            await this.videoEncoder.flush();
            this.done();
            this.unpackFilish = false;
            this.videoEncoder.close();
            this.audioEncoder.close();
            if(this.useWebcodec){
                this.videoDecoder.close();
            }
            console.log("finish")
        } 
        setTimeout(this.processQueue.bind(this), 100);
    }

    stop(){
        this.running = false;
    }

    handleVideoOutput(chunk, metadata) {
        let data = new Uint8Array(chunk.byteLength);
        chunk.copyTo(data);
        const sampleDuration = chunk.duration / (1_000_000 / this.VIDEO_TIMESCALE) * 1000;
        if (!this.videoTrack) {
            let description = metadata.decoderConfig.description
            this.videoTrack = this.outputFile.addTrak({
            type: 'video',
            width: this.outputWidth,
            height: this.outputHeight,
            avcDecoderConfigRecord: description,
            rotate: this.rotate,
          })
        }
        this.outputFile.addSample(
          this.videoTrack,
          data,
          chunk.type === 'key',
          sampleDuration,
          true
            
        );
        
        this.addedToMp4VideoFrameCount++;
        this.videoProgress(this.addedToMp4VideoFrameCount/this.totalVideoFrame);
    }

    handleAudioOutput(chunk, metadata) {
        const data = new Uint8Array(chunk.byteLength);
        chunk.copyTo(data);
        const sampleDuration = chunk.duration / (1_000_000 / this.AUDIO_TIMESCALE);
        if (!this.audioTrack) {
            this.audioTrack = this.outputFile.addTrak( {
            type: 'audio',
            sampleRate: this.targetSampleRate,
            channels: this.targetChannels,
          })
        }
        this.outputFile.addSample(
          this.audioTrack,
          data,
          chunk.type === 'key',
          sampleDuration,
          !this.videoTrack);
        this.addedToMp4AudioFrameCount++;
        this.audioProgress(this.addedToMp4AudioFrameCount/this.totalAudioFrame);
    }

    async handleVideoDecodeOutput(frame, metadata) {
        this.decodedFrameCount++;
        if (frame) {
            let keyFrame = this.decodedFrameCount % this.targetFrameRate === 0;
            if( this.skipFrames > 0 && this.decodedFrameCount % this.skipFrames === 0){
                const bitmap = await createImageBitmap(frame, {
                    resizeWidth: this.outputWidth,
                    resizeHeight: this.outputHeight,
                    resizeQuality: 'high' // 使用高质量的重置来保持图像质量
                });
                let timestamp = this.decodedFrameCount * this.frameDuration;
                const outputFrame = new VideoFrame(bitmap, {
                    timestamp: timestamp,
                    duration:  this.frameDuration
                });
                this.videoEncoder.encode(outputFrame, { keyFrame });
                outputFrame.close();
            }
            frame.close();
        }
    }

    handleError(error) {
        console.error(error);
    }

    setTotalLength(totalLength){
        this.totalLength = totalLength;
    }

    codecParams(outputWidth, outputHeight, skipFrames,  targetFrameRate, targetSampleRate, targetChannels, videoExtraData, useWebcodec,videoCodecId, rotate, totalVideoFrame,totalAudioFrame) {
        this.running = true
        this.useWebcodec = useWebcodec;
        this.outputWidth = outputWidth;
        this.outputHeight = outputHeight;
        this.skipFrames = skipFrames;
        this.targetFrameRate = targetFrameRate;
        this.targetSampleRate = targetSampleRate;
        this.targetChannels = targetChannels;
        this.rotate = rotate;
        this.totalAudioFrame = totalAudioFrame;
        this.totalVideoFrame = totalVideoFrame;
        this.shouldExcutedAudioFrameCount = 0;
        this.shouldExcutedVideoFrameCount = 0;
        this.addedToMp4AudioFrameCount = 0;
        this.addedToMp4VideoFrameCount = 0;
        this.decodedFrameCount = 0;
        this.videoTrack = null;
        this.audioTrack = null;
        this.frameDuration = 1 / targetFrameRate * 1000;
        this.configureAudioEncoder(targetSampleRate, targetChannels);
        if( outputWidth >0 && outputHeight >0 ){
            this.configureVideoEncoder(outputWidth, outputHeight, this.targetBitrate, targetFrameRate);
        }
        if (this.useWebcodec) {
            this.configureVideoDecoder(videoCodecId,videoExtraData);
        }
        this.outputFile = new MyMp4File();
        this.outputFile.createMP4File();
        
    }

    setTargetBitrate(bitrate) {
        this.targetBitrate = bitrate;
    }

    h264Packet(h264Data, isKeyframe, timestamp, duration) {
        const chunk = new EncodedVideoChunk({
            type: isKeyframe ? 'key' : "delta",
            timestamp: timestamp,
            duration: duration,
            data: h264Data
        });
        this.h264Queue.push(chunk);
        this.shouldExcutedVideoFrameCount++;
    }

    pcmData(pcmData, samples, channels, sampleRate) {
        const audioData = new AudioData({
            format: 'f32-planar', // 格式取决于你的PCM数据
            sampleRate: sampleRate,
            numberOfFrames: samples,
            numberOfChannels: channels,
            timestamp: performance.now(),
            data: pcmData
        });
        this.pcmQueue.push(audioData);
        this.shouldExcutedAudioFrameCount++;
    }

    yuvFrame(frame, keyFrame) {
        this.yuvQueue.push({frame, keyFrame});
        this.shouldExcutedVideoFrameCount++;
    }

    async endInfo() {
        console.log('unpcak finish');
        this.unpackFilish = true;
    }

    configureVideoEncoder(width, height, bitrate, frameRate) {
        this.videoEncoder = new VideoEncoder({
            output: this.handleVideoOutput.bind(this),
            error: this.handleError.bind(this),
        });
        this.videoEncoder.configure({
            codec: 'avc1.4d0034',
            width: width,
            height: height,
            bitrate: bitrate * 1000, // 目标比特率，单位为比特每秒 (bps)
            hardwareAcceleration: "prefer-hardware",
            framerate: frameRate
        });
    }

    configureAudioEncoder(targetSampleRate, targetChannels) {
        this.audioEncoder = new AudioEncoder({
            output: this.handleAudioOutput.bind(this),
            error: this.handleError.bind(this),
        });

        this.audioEncoder.configure({
            codec: 'mp4a.40.2',
            sampleRate: targetSampleRate,
            numberOfChannels: targetChannels,
            format: 'f32-planar',
        });
    }

    configureVideoDecoder(videoCodecId,videoExtraData) {
        this.videoDecoder = new VideoDecoder({
            output: this.handleVideoDecodeOutput.bind(this),
            error: this.handleError.bind(this),
        });
        switch(videoCodecId){
            case 27:
                this.videoDecoder.configure({
                    codec: 'avc1.4d0034',
                    description: videoExtraData
                });
                break;
            case 139:
                this.videoDecoder.configure({
                    codec: 'vp8',
                    description: videoExtraData
                });
                break;
            case 167:
                this.videoDecoder.configure({
                    codec: 'vp09.00.10.08',
                    description: videoExtraData
                });
                break;
        }
    }
}

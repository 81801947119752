import { Box, Button, TextField, InputAdornment, FormControl,FormLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CurrencyYuanIcon from '@mui/icons-material/CurrencyYuan';
import { useState } from 'react';

const PackageForm = ({ handleSubmit, name, setName, photoCount, setPhotoCount, price, setPrice }) => {
  const { t } = useTranslation();
  const [nameError, setNameError] = useState(false);
  const [photoCountError, setPhotoCountError] = useState(false);
  const [priceError, setPriceError] = useState(false);

  function isNumber(value) {
    return  /^(0|[1-9][0-9]*)$/.test(value);
  }


  const validateName = (name) => {
    if (!name) {
      return t("package name can not be empty")
    }
    return '';
  }

  const validatePhotoCount = (photoCount) => {
    if (!photoCount) {
      return t("photoCount can not be empty")
    }
    if( !isNumber(photoCount)) {
      return t("photoCount must be number")
    }
    if( photoCount > 1000) {
      return t("photoCount must be less than 1000")
    }
    return '';
  }

  const validatePrice = (price) => {
    if (!price) {
      return t("price can not be empty")
    }
    if( !isNumber(price)) {
      return t("price must be number")
    }
    if( price > 100000) {
      return t("price must be less than 100000")
    }
    return '';
  }

  const handleInputChange = (event) => {
    let { name, value } = event.target;
    value = value.trim()
    if (name === 'name') {
      setName(value);
      setNameError(validateName(value));
    }else if(name === 'photoCount'){
      setPhotoCount(value);
      setPhotoCountError(validatePhotoCount(value));
    }else if(name === 'price'){
      setPrice(value);
      setPriceError(validatePrice(value));
    }
  };

  const validateForm = (event) => {
    event.preventDefault();
    let nameError = validateName(name);
    let photoCountError = validatePhotoCount(photoCount);
    let priceError = validatePrice(price);

    setNameError(nameError);
    setPhotoCountError(photoCountError);
    setPriceError(priceError);


    if ( nameError || photoCountError || priceError ) {
      return;
    }
    handleSubmit(event);
  }


  return (
    <form onSubmit={validateForm} style={{ width: 500, margin: "0px auto",}}>
      <Box sx={{ display: "flex", flexDirection: "column", backgroundColor: "#ffffff", p: 2, }}>

        <FormControl margin="normal" fullWidth>
          <FormLabel>{t("Package Name")}</FormLabel>
          <TextField
            placeholder={t("Package Name")}
            type="text"
            value={name}
            size="small"
            name='name'
            error={!!nameError}
            helperText={nameError || undefined}
            onChange={handleInputChange}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormLabel>{t("Photo Limit")}</FormLabel>
          <TextField
            placeholder={t("Photo Limit")}
            type="text"
            value={photoCount}
            size="small"
            name='photoCount'
            error={!!photoCountError}
            helperText={photoCountError || undefined}
            onChange={handleInputChange}
          />
        </FormControl>

        <FormControl margin="normal" fullWidth>
          <FormLabel>{t("Price")}</FormLabel>
          <TextField
            placeholder={t("Price")}
            type="text"
            value={price}
            onChange={handleInputChange}
            name='price'
            size="small"
            error={!!priceError}
            helperText={priceError || undefined}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyYuanIcon fontSize='1em' />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            {t("Submit")}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default PackageForm;

  
const lang = {
    'Sign in': '登录',
    'Orders' :  '订单',
    'Packages' : '套餐',
    'Package List': '套餐列表',
    'Create Package': '创建套餐',
    'Package Name': '套餐名',
    'Photo Limit': '可选照片数量',
    'Price': '价格',
    'No packages available.': '没有可用的套餐',
    'Submit': '提交',
    'Edit': '编辑',
    'Edit Package': '编辑套餐',
    'Are you sure you want to delete this package?': '确定要删除此套餐吗？',
    'Cancel': '取消',
    'Confirm': '确认',
    'Package created successfully': '套餐创建成功',
    'Package updated successfully': '套餐更新成功',
    'Create Order': '创建订单',
    'Edit Order': '编辑订单',
    'Order List': '订单列表',
    'No orders available.': '没有订单',
    'Customer Name': '客户姓名',
    'Customer Phone': '客户电话',
    'Customer Info': '客户信息',
    'Shoot Location': '拍摄地点',
    'Remark': '备注',
    'Album Title': '相册标题',
    'Shoot Time': '拍摄时间',
    'Oprations': '操作',
    'Upload Pictures': '上传照片',
    'Order Management': '订单管理',
    'Select File': '选择文件',
    'No picture available.': '没有照片',
    'Picture Management': '照片管理',
    'Order Detail': '订单详情',
    'Share To Customer': '分享给客户',
    'Go Back': '返回',
    'Share': '分享',
    'Shared Link': '分享链接',
    'Password': '密码',
    'Close': '关闭',
    'Copy url and password to clipboard': '复制链接和密码到剪贴板',
    'Link': '链接',
    'Enter Code': '输入提取码',
    'Verify Code': '验证提取码',
    'Available': '可选',
    'Picture selection limit reached.': '已达到照片选择数量上限',
    'User Info': '用户信息',
    'User Name': '用户名',
    'Change Password': '修改密码',
    'Passwords do not match!': '两次输入的密码不一致！',
    'Confirm Password': '确认密码',
    'Password changed successfully': '密码修改成功',
    'Password cannot be empty': '密码不能为空',
    'Sign Up': '注册',
    'Sign In': '登录',
    '_Password Rule' : '密码应至少为6个字符，最多16个字符，可以包含字母（无论大小写）、数字以及特殊字符(@$!%*#?&)。',
    '_Username Role': '账户名应至少为1个字符，最多20个字符，只可以包含字母（无论大小写）和数字。',
    '_Phone Rule': '手机号应为11位数字',
    'Invalid username or password': '用户名或密码错误',
    'Login successfully': '登录成功',
    'Regesiter successfully': '注册成功',
    'User alreay exists': '用户已存在',
    'Please input customer name': '请输入客户姓名',
    'Please select a package': '请选择套餐',
    'Upload Files': '上传文件',
    'Are you sure you want to delete this order?': '确定要删除此订单吗？',
    'User Agreement': '用户协议',
    'Loading': '加载中',
    'Text copied to clipboard': '文本已复制到剪贴板',
    'Failed to copy text.': '复制文本失败',
    'No picture is uploading.': '没有照片正在上传',
    'InvitationCode can not be null!': '邀请码不能为空！',
    'Invitation Code': '邀请码',
    'Invitation code error': '邀请码错误',
    'No token provided.': '未提供登录信息',
    'Failed to authenticate token.': '登录信息验证失败',
    'Package': '套餐',
    'alias': '别名',
    'Welcome back,': '欢迎回来',
    'Don’t have an account?': '还没有账号？',
    'Sign up for free': '免费注册',
    'Create Account': '创建账号',
    'Already have an account?': '已有账号？',
    'Login': '登录',
    'By clicking Sign up, you agree to our': '点击注册，即表示您同意我们的',
    'Terms and Privacy Policy.': '条款和隐私政策',
    '2 days': '2天',
    '3 days': '3天',
    '7 days': '7天',
    '14 days': '14天',
    '30 days': '30天',
    '{{day}} days left': '还剩 {{day}} 天',
    'Expired':"已经过期",
    'Expire': '有效期',
    'Save Share': '保存分享',
    'Cancel Share': '取消分享',
    'Password format error': '密码格式错误',
    'Package Name is required': '套餐名不能为空',
    'Photo Limit format is incorrect': '可选照片数量格式错误',
    'Price format is incorrect': '价格格式错误',
    'Password must be 4 characters long and only contain letters and numbers': '密码必须为4个字母或数字',
    'Send Code': '发送验证码',
    'phone': '手机号',
    'Code is wrong': '验证码错误',
    '_Captcha Rule': '验证码应为4个字符',
    'Phone code' : '手机验证码',
    'Captcha' : '验证码',
    'Forget Password': '忘记密码',
    'Reset Password': '重置密码',
    'phone num can not be empty': '手机号不能为空',
    'password can not be empty': '密码不能为空',
    'Code can not be empty': '手机验证码不能为空',
    'Code format error': '手机验证码格式错误',
    'Captcha can not be empty': '验证码不能为空',
    'Captcha format error': '验证码格式错误',
    'Reset password successfully': '重置密码成功',
    'photoCount can not be empty': '可选照片数量不能为空',
    'package name can not be empty': '套餐名不能为空',
    'photoCount must be number': '可选照片数量必须为数字',
    'price can not be empty': '价格不能为空',
    'price must be number': '价格必须为数字',
    'photoCount must be less than 1000': '可选照片数量必须小于1000',
    'price must be less than 100000': '价格必须小于100000',
    'customer name can not be empty': '客户姓名不能为空',
    'customer phone is invalid': '客户电话格式错误',
    ' seconds remaining': ' 秒后重新发送',
    'Password is wrong': '密码错误',
    'Subscription end date': '订阅到期时间',
    'Upgrade': '升级',
    'No products': '没有可用的产品',
    'Subscribe Update': '订阅更新',
    'Month': '月',
    'Duration': '时长',
    'Space Limit': '空间限制',
    'Purchase': '购买',
    'Total Price': '总价',
    'No subscriptions': '没有订阅',
    'Subscriptions List': '订阅记录',
    'Status': '状态',
    'pending': '待支付',
    'completed': '已完成',
    'Amount': '金额',
    'Alipay Order Number': '支付宝订单号',
    'Order Number': '订单号',
    'Subscription Time': '订阅时间',
    'No such subscription': '没有此订阅',
    'Subscription payment status': '订阅支付状态',
    'Refresh Status': '刷新状态',
    'Image dimensions exceed the maximum allowed size.':'图片的长款超过了最大限制',
    'File name is:':'文件名:',
    'The max with and height of your picture will be optimized to:':'图片的最大宽高将被优化为:',
    'pixels':'像素',
    'If you want to use a bigger picture, please':'如果您想使用更大的图片，请',
    'File is not a jpg or png image.':'文件不是jpg或png格式的图片',
    'Upload File': '上传文件',
    'Claim Album': '确认选择',
    'Unclaim Album': '取消选择',
    'Album claimed successfully.': '确认选择成功',
    'Album unclaimed successfully.': '取消选择成功',
    'Customer Selected': '客户已选择',
    'You have {{hours}} hours left to use this platform.': '您还有 {{hours}} 小时可以使用此平台',
    'You have {{days}} days left to use this platform.': '您还有 {{days}} 天可以使用此平台',
    'Your subscribe is expired': '您的订阅已过期',
    'The picture will only be available for the next {{day}} days. Please make your choice soon!': '照片将在{{day}}天后失效，请尽快选择！',
    'Already the first picture': '已经是第一张照片了',
    'Last picture': '已经是最后一张照片了',
    'Uploaded pictures': '已上传',
    'pics': '张',
    'Claimed pictures': '已选择照片',
    'claimed': '已选',
    'selectable images': '可选',
    'Share detail': '分享详情',
    'not shared': '未分享',
    'Shared': '已分享',
    'days': '天',
    'Selected': '已选',
    'Unselected': '未选',
    'Select detail': '选择详情',
    'Member': '会员',
    'Member plan': '会员计划',
    'for individuals': '适用于个人',
    'Include': '包含',
    'Storage': '存储空间',
    'MaxDimenstion': '照片最大尺寸',
    'VIP':"高级会员",
    'for company': '适用于公司',
    'Subscribe': '订阅',
    'code cannot be empty': '提取码不能为空',
    'code is wrong': '提取码错误',
    'Code': '提取码',
    'You are not confirm your choose,Are you sure you want to leave this page?': '您还没有确认选择，确定要离开此页面吗？',
    'Upload Complete': '上传完成',
    'Watermark': '水印',
    'Style': '样式',
    'Single': '单行',
    'Fill': '填充',
    'Watermark can not be empty': '水印内容不能为空',
    'Save': '保存',
    'Watermark saved successfully': '水印保存成功',
    'Watermark must less than 5 letters': '水印内容不能超过5个字母',
    'Order Info': '订单详情',
    'Related Package' : '所属套餐',
    'Message Board' :'留言板',
    'If you have any suggestions or complaints, please feel free to leave us a message.' : '如果有任何意见和建议，请给我们留言.',
    'Enter your message here' :'请在这里输入留言',
    'Reply type':'回复方式',
    'callback':'回电话',
    'wechat':'加微信回复',
    'messageBoard':'留言板回复',
    'Content is too long':'留言内容太长',
    'Content can not be empty':'留言不能为空',
    'Message submited successfully':"留言提交成功",
    'Admin says:':'管理员回复:',
    'add my wechat':'请添加我的微信',
    'call me please.':"请给我打电话.",
    'Basic Member': "试用会员",
    'Intermediate Member':"会员",
    'Advanced Member':"高级会员",
    'UserName updated!':"用户名修改成功",
    'Delete':"删除",
    'Set as cover':"设为封面",
    'Preview':"预览",
    'Cover':"封面",
    'You are currently subscribed to the {{plan}}, which offers images at a resolution of {{resolution}}. You have the option to upgrade to the {{nexPlan}} for higher quality images.' : '您当前订阅的是{{plan}}，提供的图片分辨率为{{resolution}}。您可以选择升级到{{nexPlan}}，以获得更高质量的图片。',
    'Multi select': "批量选择",
    'Are you sure you want to delete those pictures?':"确定要删除这些照片吗？",
    'Commit select':"提交选择",
    'Select again':"重新提交",
    'Picture Preview':"照片预览",
    'Select this picture':"选择这张照片",
    'Unselect this picture':"取消选择",
    'picsect':"图派",
    'Username': '用户名',
    'Change Name': '修改用户名',
    '_Username Rule':' 用户名应至少为6个字符，最多20个字符，只可以包含字母（无论大小写）和数字。',
    'Generate': '生成',
    'Copy Names': '复制文件名',
    'Text copied to clipboard': '文本已复制到剪贴板',
    'Error to write to clipboard': '复制文本失败',
    'why should I needs those names?': '为什么我需要这些文件名？',
    'Learn how to filter selected photos in Lightroom.': '了解如何在Lightroom中筛选选定的照片。',
    'Open Selection Tool': '打开选图工具',
    //error from server
    'Internal Server error, please try again later.' : '服务器错误，请稍后再试。',
    'share was expired' : '分享已过期',
    'Album not found' : '相册未找到',
    'Picture selection limit reached.' : '已达到照片选择数量上限',
    'Order or album not found' : '订单或相册未找到',
    'Wrong password': "提取码错误",
    'Invalid phone or password': '手机号或密码错误',
    'Captcha expired': '验证码已过期',
    'Captcha error': '验证码错误',
    'Phone alreay exists': '手机号已存在',
    'Code was expired': '验证码已过期',
    'Send successfully': '发送成功',
    'Code already sent': '验证码已发送',
    'Code error or expired': '验证码错误或已过期',
    'Package is in use. please delete the orders first.': '套餐正在使用中，请先删除订单。',
    'Wechat Sign in': '微信登录',
    'Already scaned': '已扫描',
    'Open files from computer': '从电脑打开文件',
    'Drop files here to view and convert': '拖拽文件到此处查看或转换',
    'Add a file': '添加文件',
    'Download this image': '下载此图片',
    'Processing': '处理中',
    'The image is not supported': '不支持此图片',
    'Uploading {{num}} pictures': '正在上传 {{num}} 张照片',
    'Setting': '设置',
    'Support': '支持',
    'My': '我的',
    'Position': '位置',
    'Top Left': '左上',
    'Top Right': '右上',
    'Bottom Left': '左下',
    'Bottom Right': '右下',
    'Center': '中心',
    'Font Size': '字体大小',
    'Small': '小',
    'Medium': '中',
    'Large': '大',
    'X-Large': '特大',
    'Selection Setting': '选图设置',
    'Selection Result': '选图结果',
    'Archive': '分拣源照片',
    'Open local directory failed': '打开本地文件夹失败',
    'Local files': '本地文件',
    'Write to a new directory': '写入新目录',
    'Write to new directory successfully': '写入新目录成功',
    'Write to new directory failed': '写入新目录失败',
    'Filter': '筛选',
    'show image name': '显示图片名',
    'force limit': '强制限制',
    'append picture price': '加片价格',
    'Update Success': '更新成功',
    'over-selection allowed': '允许超选',
    'over-select {{over}} pics already': '已超选 {{over}} 张',
    'need pay {{price}} Yuan': '需支付 {{price}} 元',
    'Image information': '图片信息',
    'Brightness': '亮度',
    'Contrast': '对比度',
    'Saturation': '饱和度',
    'Hue': '色调',
    'Value': '明度',
    'Temperature': '色温',
    'Alpha': '透明度',
    'Watermark setting': '水印设置',
    'Watermark has been set': '水印已设置',
    'Every photo uploaded from now on will be marked with the watermark.': '从现在开始上传的每张照片都将带有水印。',
    'Set it again': '重新设置',
    'random': '系统随机',
    'Code must be 4 numbers': '提取码必须为4位数字',
    'available for the next {{day}} days': '在接下来的{{day}}天内有效',
    'share link not been set': '分享链接未设置',
    'share link has been set': '分享链接已设置',
    'Do not metion me again': '不再提醒',
    'Preview selection pdf': '预览选择结果PDF',
    'Preview selection' : '预览选择结果',
    'Settlement Statement': '结算单',
    'Learn how to sperate picture.': '了解如何分拣照片。',
    'Are you sure you want to leave this page? The upload processing not be done.': '确定要离开此页面吗？上传处理尚未完成。',
    '(Optional)': '(可选)',
    'Copy image to clipboard': '复制图片到剪贴板',
    'Copy image faild': '复制图片失败',
    'Copy image success': '复制图片成功',
    'Auto fill password': '自动填充密码',
    'Pictures': '照片',
    'Out of space and you can:': '空间不足，您可以：',
    'Upgrade to a higher plan': '升级到更高的套餐',
    'You have 5G spaces to get': '您有5G空间可领取',
    'Or': '或',
    'Request more space': '领取更多空间',
    'extra space added': '额外空间已添加',
    'force limit(no extra pics)': '强制限制(不加片)',
    'Next Step': '下一步',
    'pricePerPic can not be empty': '加片价格不能为空',
    'pricePerPic must be number': '加片价格必须为数字',
    'OpenWatermark': '打开水印',
    'User not yet selected': '用户尚未选择',
    'Global Watermark Setting': '全局水印设置',
    'The watermark will be enabled when the next file is uploaded.': '水印将在下一个文件上传时才能启用。',
    'Miniprogram selection program, easy to use': '小程序选图，使用简单',
    'Open with miniprogram': '小程序打开',
    "Select setting can't be modified if user selected.": "用户已选择后，无法修改选择设置。",
    'Photos': '照片',
    'Space Used': '已用空间',
    'counting...': '计算中...',
    'Reset Selection': '重置选择',
    'Selections Logs': '选图记录',
    'Are you sure to commit those selections?': '确定提交这些选择吗？',
    'Are you sure to reset those selections?': '确定重置这些选择吗？',
    'Delivery': '交付',
    'negative': '底片',
    'retouching': '精修',
    'Delivery Share': '交付分享',
    'The picture will only be available for the next {{day}} days. Please save your photo soon!': '照片将在接下来的{{day}}天内有效，请尽快保存您的照片！',
    'Download link details': '下载链接详情',
    'Generate Link': '生成链接',
    'Last generate time': '上次生成时间',
    'Not generate yet': '尚未生成',
    'Photo numer when generate is': '生成时的照片数量是',
    'Download link': '下载链接',
    'Generating download link': '正在生成下载链接',
    'The number of images allowed in the package is limited to {{number}} in each order.': '可以打包次数被限制为{{number}}次/每订单。',
    'You have generated {{number}} download links, and you can generate {{limit}} more.': '您已生成{{number}}个下载链接，还可以生成{{limit}}个。',
    'Save file to local': '保存到本地',
    'Resume': '继续',
    'Download': '下载',
    'Download again' : '重新下载',
    'There are no photos in the album.': '相册中没有照片。',
    'Selection': '选图',
    'Member Only': '会员专享',
    'One picture can only appear in one order. So if you upload a picture that already exists in the order, it will be ignored.': '一张照片只能出现在一个订单中。因此，如果您上传的照片已经存在于订单中，则会被跳过。',
    'Open local directory canceled': '取消打开本地文件夹',
    'Open local directory failed': '打开本地文件夹失败',
    'Select a directory to save': '下载所有到文件夹',
    'Pack images': '打包图片',
    'Download': '下载',
    'Something wrong with write file ,Please use pack file button': '写文件出现问题，请使用打包文件按钮',
    'Done': '完成',
    'Download completed': '下载完成',
    'Add to nagetive folder of dilivery at the same time while uploading, And deletion will be synced too.': '上传时同时复制到交付的底片文件夹，删除也会同步。',
    'The number of pictures in the negative folder of the delivery is limited to {{num}}.': '交付的底片文件夹中的照片数量被限制为{{num}}。',
    'Uploaded {{pictureNum}} pictures, seccess: {{successFileCount}} ,fail: {{failFileCount}},exists {{existFileCount}}.':'上传了{{pictureNum}}张照片，成功{{successFileCount}}张，失败{{failFileCount}}张，已存在{{existFileCount}}张。',
    'Uploaded {{pictureNum}} pictures to nagetive folder of dilivery, seccess: {{successPhotoCount}}, fail:{{failPhotoCount}} ,exists {{existPhotoCount}}.':'\n上传了{{pictureNum}}张照片到交付的底片文件夹，成功{{successPhotoCount}}张，失败{{failPhotoCount}}张，已存在{{existPhotoCount}}张。',
    'The number of pictures you can upload is limited to {{limit}}': '您可以上传的照片数量限制为{{limit}}',
    'Select a Video from computer': '从电脑选择视频',
    'A video is processing': '视频正在处理中',
    'A free account is limited to the creation of only one order.': '免费账户仅限创建一个订单。',
    'A free account is limited to 300 pictures.': '免费账户限制为300张照片。',
    'Delivery Shared': '交付已分享',
    'Selected pictures num is:': '已选照片数量为:',
    'Total num is:': '总数为:',
    'Delivery pictures num is:': '交付照片数量为:',
    'Subscription End Date': '到期时间',
    'WeChat browser does not support downloading images, please open link in your brower.': '微信浏览器不支持下载图片，请在浏览器中打开链接。',
    'Copy url to clipboard': '复制链接到剪贴板',
    'PhotoShared Link': '照片分享链接',
    'Details': '详情',
    'Selection Detail': '选图详情',
    'Selection Records': '选图记录',
    'Redirect to miniprogram to select pictures.': '重定向到小程序选择照片。',
}
export default lang;
import Pagination from '@mui/material/Pagination';

export default function CustomPagination(props) {

  return (
    <Pagination 
        sx={{
            width: '100%',
            '& ul': {                   // 针对内部的 ul
                height: '60px',             // 定制高度
                display: 'flex',
                alignItems: 'center',       // 上下居中
                justifyContent: 'flex-end', // 左右靠右
            },
        }}
    {...props} />
  );
}

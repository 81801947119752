import { makeAutoObservable, action, observable } from "mobx";
import { getSpaceUsedInUnit } from "../util";
import { redirect } from "react-router-dom";
class UserStore {
  username = null;
  token = null;
  shouldRedirectToLogin = false;
  spaceLimit = 0;
  spaceUsed = 0;
  maxDimension = 0;
  subscriptionEndDate = null
  openWatermark = false
  watermark = null
  watermarkPosition = null
  watermarkFontSize = 0
  watermarkStyle = 0
  watermarkAlpha = 0
  noticeUpgrade = false
  level = 0;
  packageImagesLimit = 0;

  constructor() {
    makeAutoObservable(this, {
      username: observable,
      token: observable,
      maxDimension:observable,
      level:observable,
      packageImagesLimit:observable,
      shouldRedirectToLogin: observable,
      subscriptionEndDate: observable,
      openWatermark:observable,
      watermark:observable,
      watermarkPosition:observable,
      watermarkFontSize:observable,
      watermarkStyle:observable,
      watermarkAlpha:observable,
      spaceUsed: observable,
      spaceLimit: observable,
      extraSpace: observable,
      setShouldRedirectToLogin: action,
      login: action,
      logout: action,
      checkLogin: action,
      setUserInfo: action,
      addSpaceUsed: action,
      reduceSpaceUsed: action,
      noticeUpgrade:observable,
      setNoticeUpgrade:action
    });
  }

  get limit(){
    return this.level ==2 ? 500 : 300;
  }

  get spaceUsedInUnit() {
    return getSpaceUsedInUnit(this.spaceUsed)
  }

  get spaceLimitInUnit() {
    return getSpaceUsedInUnit(this.spaceLimit)
  }

  addExtraSpace(size) {
    this.spaceLimit += size;
    this.extraSpace += size;
  }

  addSpaceUsed(size) {
    this.spaceUsed += size;
  }
  reduceSpaceUsed(size) {
    this.spaceUsed -= size;
    if (this.spaceUsed < 0) {
      this.spaceUsed = 0;
    }
  }

  setUserInfo(username, spaceUsed, spaceLimit,extraSpace,maxDimension,level,subscriptionEndDate,openWatermark,watermark,watermarkPosition,watermarkFontSize,watermarkStyle,watermarkAlpha,noticeUpgrade,packageImagesLimit) {
    this.username = username;
    this.spaceUsed = spaceUsed;
    this.spaceLimit = spaceLimit;
    this.extraSpace = extraSpace;
    this.maxDimension = maxDimension; 
    this.level = level; 
    this.subscriptionEndDate = subscriptionEndDate;
    this.openWatermark = openWatermark;
    this.watermark = watermark;
    this.watermarkPosition = watermarkPosition;
    this.watermarkFontSize = watermarkFontSize;
    this.watermarkStyle = watermarkStyle;
    this.watermarkAlpha = watermarkAlpha;
    this.noticeUpgrade = noticeUpgrade;
    this.packageImagesLimit = packageImagesLimit;
  }

  isWatermarkSet() {
    if( this.watermarkStyle == "single" ){
      return this.watermark?.length > 0 && this.watermarkPosition?.length > 0 && this.watermarkFontSize > 0  && this.watermarkAlpha > 0;
    }else{
      return this.watermark?.length > 0 && this.watermarkFontSize > 0  && this.watermarkAlpha > 0;
    }
   }

  setNoticeUpgrade(value){
    this.noticeUpgrade = value;
  }

  setShouldRedirectToLogin(value) {
    this.shouldRedirectToLogin = value;
  }

  login(token) {
    this.token = token;
    this.shouldRedirectToLogin = false;
    localStorage.setItem('token', token);
  }

  logout() {
    this.token = null;
    this.shouldRedirectToLogin = true;
    localStorage.removeItem('token');
    redirect('/login');
  }

  checkLogin() {
    const token = localStorage.getItem('token');
    if (token) {
      this.token = token;
      return true;
    } else {
      this.shouldRedirectToLogin = true;
      return false;
    }
  }
}



//匿名用户 store
class AnonymousUserStore {
  accessToken = null;

  constructor() {
    makeAutoObservable(this);
  }

  save(accessToken) {
    this.accessToken = accessToken;
    localStorage.setItem('x-access-token', accessToken);
  }

  empty() {
    this.accessToken = null;
    localStorage.removeItem('x-access-token');
  }

  check() {
    const accessToken = localStorage.getItem('x-access-token');
    if (accessToken) {
      this.accessToken = accessToken;
      return true;
    }else{
      return false;
    }
  }
}

const userStore = new UserStore();
const anonymousUserStore = new AnonymousUserStore();

export {userStore,anonymousUserStore}

import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Button, Checkbox, Dialog, IconButton, Slide, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useMemo,useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import theme from '../../theme';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import defaultPicture from '../../assets/default.jpg';
import Empty from '../../components/Empty';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomerPhotoDialog = inject('snackbarStore')(observer(({
  albumClaimed,
  open,
  setOpen,
  currentIndex,
  setCurrentIndex,
  previewPictures,
  snackbarStore,
}) => {

  const [animation, setAnimation] = useState(false);

  const { t } = useTranslation();

  const handleClose = (event, reason) => {
    setAnimation(false)
    setOpen(false)
  };

  const handleNext = useCallback(() => {
    const lastIndex = previewPictures.length;
    if (currentIndex < lastIndex - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      snackbarStore.showSnackbar(t('Last picture'), 'warning')
    }
  },[previewPictures,currentIndex,snackbarStore,t]);

  const handlePrev =useCallback( () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else {
      snackbarStore.showSnackbar(t('Already the first picture'), 'warning')
    }
  },[previewPictures,currentIndex,snackbarStore,t]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrev,
  });

  const imageStyle = {
    height: "60px",
    margin: "10px",
    border: "3px solid #fff",
    cursor: "pointer",
  }


  useEffect(() => {
    function handleKeyPress(event) {
      switch (event.key) {
        case 'ArrowLeft':
          handlePrev()
          break;
        case 'ArrowRight':
          handleNext()
          break;
        default:
          // 处理其他按键
          break;
      }
      event.preventDefault();
      event.stopPropagation();
    }
    if(open){
      window.addEventListener('keydown', handleKeyPress);
    }

    // 组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [open,handlePrev,handleNext]);


  if (!open) {
    return null
  }

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      keepMounted
      disablebackdropclick="true"
      maxWidth={false}
      onLoad={() => setAnimation(true)}
      PaperProps={{
        style: {
          backgroundColor: 'white',
          height: "100%",
          display: "flex"
        },
      }}
    >

      <Box sx={{ height: "60px", zIndex: 10, display: "flex", justifyContent: "center", alignItems: "center" }}>
        <IconButton onClick={handleClose} sx={{ width: "60px", color: theme.palette.primary.main }}>
          <ArrowBackIosIcon />
        </IconButton>
        <Typography sx={{ flex: 1, marginRight: "60px", textAlign: "center", fontSize: "1rem" }}>{t("Picture Preview")}</Typography>
      </Box>


      <Box
        {...handlers}
        sx={{ flex: 1, display: "flex", flexDirection: "row", width: "100%" }}>
        <Box sx={{
          display: {
            xs: "none",
            md: "flex"
          },
          alignItems: "center", justifyContent: "center", width: 100
        }}>
          <IconButton onClick={handlePrev} sx={{ color: '#fff', backgroundColor: theme.palette.primary.light }}>
            <NavigateBefore />
          </IconButton>
        </Box>

        <Box
          style={{ 
            height:"100%", 
            width: "100%", 
            overflow: "hidden"
          }}
        >
          <Box
            style={{
              height: "100%",
              width: "100%", 
              transition: animation ? "transform 0.3s ease-in-out" : "",
              transform: `translateX(-${currentIndex * 100}%)`
            }}
          >
            {previewPictures?.map((picture, index) => {
              if( currentIndex <= index + 1 && currentIndex >= index - 1){
                return <Box key={index}
                sx={{
                  display: 'flex',
                  flex: 1,
                  flexDirection: 'column',
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  left: 0,
                  top: "50%",
                  height: "100%",
                  width: "100%",
                  transform: `translate(${index * 100}% ,-50%)`,
                  backgroundColor: "black"
                }}>
                {<img alt={`slide-${index}`} src={currentIndex <= index + 1 && currentIndex >= index - 1 ? picture?.publicUrl : defaultPicture} style={{ width: "100%", height: "100%", objectFit: "contain", objectPosition: "center" }} />}
               
                </Box>
              }else{
                return null
              }
            })}
            {previewPictures.length === 0 && <Empty message={t("No picture available.")} />}
          </Box>
        </Box>

        <Box sx={{
          display: {
            xs: "none",
            md: "flex"
          }, alignItems: "center", justifyContent: "center", width: 100
        }}>
          <IconButton onClick={handleNext} color='primary' sx={{ color: '#fff', backgroundColor: theme.palette.primary.light }}>
            <NavigateNext />
          </IconButton>
        </Box>
      </Box>

    </Dialog>
  );
}));

export { CustomerPhotoDialog };

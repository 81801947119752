import React, { useEffect } from 'react';
import withAuth from '../../HOC/withAuth';
import { Box , Typography ,Table,TableContainer,TableHead,TableRow,TableCell,TableBody } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import http from '../../http';
import {useState} from 'react'
import Empty from '../../components/Empty'
import theme from '../../theme'
import dayjs from 'dayjs'


const SubscriptionIndex = inject()(observer(() => {
  const { t } = useTranslation();
  const [bookings,setBookings] = useState([])
  useEffect(() => {

    http.get(`/user/subscriptions`).then(res => {
        setBookings(res.data.bookings)
    })
  },[])
  return (
    <Box sx={{display:"flex",flexDirection:"column",height:"100%", p:2 }} >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h3">
          {t("Subscriptions List")}
        </Typography>

      </Box>
      <Box sx={{ display: 'flex',flex:1, flexWrap: 'wrap',borderRadius:1,p:2 }}>
        {
            bookings.length > 0 ? <TableContainer sx={{backgroundColor:"#ffffff",borderRadius:1,mt:2}}>
            <Table>
              <TableHead>
                <TableRow >
                  <TableCell>{t("Order Number")}</TableCell>
                  <TableCell>{t("Subscription Time")}</TableCell>
                  <TableCell>{t("Duration")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>{t("Amount")}</TableCell>
                  <TableCell>{t("Alipay Order Number")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bookings.map((bookings) => (
                  <TableRow key={bookings.id}>
                    <TableCell>{bookings.id}</TableCell>
                    <TableCell>{ dayjs(bookings.startDate).format("YYYY-MM-DD") } - { dayjs(bookings.endDate).format("YYYY-MM-DD") }</TableCell>
                    <TableCell>{bookings.duration} {t("Month")}</TableCell>
                    <TableCell>{t(bookings.status)}</TableCell>
                    <TableCell>¥{bookings.amount}</TableCell>
                    <TableCell>{bookings.trade_no}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer> :  <Empty message={t("No subscriptions")} />
        }
      </Box>
    </Box>
  );
}));

export default withAuth(SubscriptionIndex);

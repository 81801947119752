import React, { useState, useEffect } from 'react';
import http from '../../http';
import withAuth from '../../HOC/withAuth';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import PackageForm from './form';
import { useTranslation } from 'react-i18next';
import { observer, inject } from 'mobx-react';
import theme from '../../theme';
import Back from '../../components/Back';

const EditPackageForm = inject('snackbarStore')(observer(({snackbarStore}) => {
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [photoCount, setPhotoCount] = useState(0);
  const [price, setPrice] = useState(0);
  const navigate = useNavigate();
  const { packageId } = useParams(); 
  const {t} = useTranslation();

  useEffect(() => {
    http.get(`/user/packages/${packageId}`)
      .then(response => {
        let pkg = response.data.package
        setName(pkg.name);
        setPhotoCount(pkg.photoCount);
        setPrice(pkg.price);
        setLoading(false);
      })
      .catch(error => {});
  }, [packageId,snackbarStore]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const packageData = {
      name,
      photoCount,
      price,
    };

    http.put(`/user/packages/${packageId}`, packageData)
      .then(response => {
        snackbarStore.showSnackbar('Package updated successfully')
        navigate('/user/packages')
      })
      .catch(error => {});
  };

  return (
    <Box sx={{display:"flex",flexDirection:"column",height:"100%", p:2 }} >
      <Box sx={{ display: 'flex',  alignItems: 'center', marginBottom: 2 }}>
        <Back />
        <Typography variant="h3">
          {t("Edit Package")}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex',flex:1, p:10 }}>
        <PackageForm 
          handleSubmit={handleSubmit} 
          name={name} 
          setName={setName} 
          photoCount={photoCount} 
          setPhotoCount={setPhotoCount} 
          price={price} 
          setPrice={setPrice} 
          loading={loading}
        />
      </Box>
    </Box>
  );
}));

export default withAuth(EditPackageForm);

import { useState } from 'react';
import { Box, Dialog, DialogTitle, Typography, Grid, DialogContent, DialogActions, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { inject, observer } from 'mobx-react';
import logo from '../assets/logo.png'

const useLargeWidth = 20

const OrderContent = inject('pictureStore')(observer(({ pictureStore, order }) => {
  const { t } = useTranslation()
  return  <Box sx={{
      flexDirection: 'column',
      backgroundColor: '#FFFFFF',
      mt:1,
    }}>
      <Box sx={{
        display:"flex",
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <img style={{width: 50, height: 50,}} src={logo} />
        <Typography variant='h6' sx={{flex:1,textAlign:"center"}} >{order?.Album?.title}</Typography>
        <Box sx={{
          display:"flex",
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-end',
        }}>
          <Typography variant='subtitle2' sx={{textAlign:"right"}} >{t('selectable images')+order?.photoCount+t("pics")}  </Typography>
          <Typography variant='subtitle2'>{t("claimed")} {order.claimedPhotoCount} {t("pics")}</Typography>
        </Box>
       
      </Box>
      <Grid sx={{mt:1}} container spacing={2}>
        {pictureStore?.selectedPictures?.map((file,index) => {
          return <Grid key={index} item sm={pictureStore?.selectedPictures?.length>useLargeWidth?2:3}  >
            <img style={{
              width: '100%',
              height: 100, 
              resizeMode: 'contain'  // 确保图片内容适应容器大小
            }} src={file.publicUrlSmall} />
            <Typography variant='body2' sx={{mt:1,fontSize:"0.75rem",textAlign: 'center'}} >{file.originalFileName}</Typography>
          </Grid>
        })}
      </Grid>
      {!order.forceLimit && <Box sx={{ mt: 2 }}>
        <Typography variant='subtitle2' sx={{textAlign:"right"}} >{t('over-select {{over}} pics already', { over: Math.max(order.claimedPhotoCount - order.photoCount,0) })}</Typography>
        <Typography variant='subtitle2' sx={{textAlign:"right"}}>{t('need pay {{price}} Yuan', { price: ((order.claimedPhotoCount - order.photoCount) * order.pricePerPic) })}</Typography>
      </Box>}
    </Box>
}));


export default OrderContent
import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';

function IconButtonWithText({icon,text,onClick}) {
  return (
    <Stack onClick={onClick} direction="row" alignItems="center" spacing={1}>
      <Button variant="contained" color="primary" >
        <Stack direction="row" alignItems="center" spacing={1}>
          {icon}
          <Typography variant='subtitle2' sx={{color:"white"}}>{text}</Typography>
        </Stack>
      </Button>
    </Stack>
  );
}

export default IconButtonWithText;

import AddIcon from '@mui/icons-material/Add';
import Search from '@mui/icons-material/Search';
import { Box, Button, Grid, IconButton, InputAdornment, Link, Menu, MenuItem, Select, TextField, Typography } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import withAuth from '../../HOC/withAuth';
import Empty from '../../components/Empty';
import CustomPagination from '../../components/Pagination';
import ShareDialog from '../../components/ShareDialog';
import OrderInfo from '../../components/OrderInfo';


function SearchBar({ ordersStore }) {
  const [searchType, setSearchType] = useState('name');
  const [searchText, setSearchText] = useState('');
  const { t } = useTranslation();

  const handleSearchTypeChange = (event) => {
    setSearchType(event.target.value);
  };

  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    ordersStore.searchOrders(searchType, searchText);
  };

  return (
    <Box sx={{ display: 'flex', alignItems: "center", width: "100%", backgroundColor: "#ffffff" }}>
      <Select size='small' value={searchType} onChange={handleSearchTypeChange} >
        <MenuItem value={'name'}>{t('Customer Name')}</MenuItem>
        <MenuItem value={'phone'}>{t('Customer Phone')}</MenuItem>
        {/* 其他搜索类型 */}
      </Select>
      <TextField
        sx={{ ml: 1, maxWidth: 500 }}
        fullWidth
        size='small'
        value={searchText}
        onChange={handleSearchTextChange}
        onKeyPress={handleKeyPress}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <IconButton onClick={handleSearch}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}


const OrdersTable = ({ orders, ordersStore, dialogStore }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const open = Boolean(anchorEl);

  const onClickMore = (target,order) => {
    setAnchorEl(target);
    setSelectedOrder(order)
  };
  const onClickShre = (order) => {
    ordersStore.setCurrentOrder(order)
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onOrderDelte = useCallback((orderId) => {
    dialogStore.openDialog("Are you sure you want to delete this order?", () => {
      ordersStore.deleteOrder(orderId)
      setAnchorEl(null);
    })
  }, [dialogStore, ordersStore])

  return (
    <Grid container spacing={2}   >
      {orders.map((order) => (
        <Grid item sm={4} md={3} lg={2} key={order.id} >
          <OrderInfo order={order} onOrderDelte={onOrderDelte} onClickShre={onClickShre} />
        </Grid>

      ))}
      <ShareDialog />
    </Grid>
  );
};

const OrderList = inject('ordersStore', 'dialogStore','userStore','snackbarStore')(observer(({ ordersStore, dialogStore,userStore,snackbarStore }) => {
  let paginatedOrders = ordersStore.paginatedOrders
  let totalPage = ordersStore.totalPage
  let page = ordersStore.page

  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlePageChange = (event, value) => {
    ordersStore.setPage(value);
  };

  const handleAddOrder = () => {
    if( userStore.level == 0 && ordersStore.orders.length >= 1){
      snackbarStore.showSnackbar(t("A free account is limited to the creation of only one order."),'error');
    }else{
      navigate('/user/orders/create')
    }
  }

  useEffect(() => {
    ordersStore.fetchOrders();

  }, [ordersStore]);


  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", p:2  }} >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2, height: 40 }}>
        <Typography variant="h3">
          {t("Order List")}
        </Typography>

        <Button variant="contained" color="primary" onClick={handleAddOrder}>
          <AddIcon fontSize='small' />{t("Create Order")}
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: "white", flex: 1, overflowY: "auto" }}>
        <SearchBar ordersStore={ordersStore} />
        {paginatedOrders?.length > 0 ? (
          <OrdersTable orders={paginatedOrders} ordersStore={ordersStore} dialogStore={dialogStore} />
        ) : (
            <Empty message={t("No orders available.")} >
              <Button sx={{mt:2}} variant="contained" color="primary" onClick={() => navigate('/user/orders/create')}>
                <AddIcon fontSize='small' />{t("Create Order")}
              </Button>
            </Empty>
        )}
        {totalPage > 1 && <CustomPagination count={totalPage} page={page} onChange={handlePageChange} />}

      </Box>
    </Box>
  );
}));

export default withAuth(OrderList);

import { Box, Button, Typography, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams , useNavigate} from 'react-router-dom';
import withAuth from '../../../HOC/withAuth';
import Empty from '../../../components/Empty';
import OrderContent from '../../../components/OrderContent';
import StepsSideBar from '../../../components/StepsSideBar';
import http from '../../../http';
import theme from '../../../theme';
import IconButtonWithText from '../../../components/IconButtonWithText';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import defaultPicture from '../../../assets/default.jpg';



const HoverHandles = observer(({ selection  }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleGoToDeail = () => {
        navigate('/user/selections/' + selection.id);
        return
    }
    return (
      <Box sx={{
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 100
      }}>
        <IconButtonWithText text={t("Details")} icon={<PhotoLibraryIcon sx={{fontSize:"1.2rem"}} />} onClick={handleGoToDeail} />
        
      </Box>
    )
  })

  const SelectionCard = observer(({ selection }) => {
    const { t } = useTranslation();
    const positions = ['top left', 'top right', 'bottom left', 'bottom right'];
    const sizes = ['50% 50%', '50% 50%', '50% 50%', '50% 50%']; 
    const backgroundImage = selection?.cover?.map(cover => {
        if(cover){
            return `url(${cover.publicUrlSmall})`
        }else{
            return `url(${defaultPicture})`
        }
    }).join(', ');
    const backgroundPosition = positions.slice(0, selection?.cover?.length).join(', ');
    const backgroundSize = sizes.slice(0, selection?.cover?.length).join(', ');
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Grid item sm={3}  >
        <Box
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{
                borderWidth: "1px",
                borderStyle: "solid",
                borderColor: theme.palette.grey[300],
                borderRadius: "10px",
                overflow: "hidden",
                position: "relative",
                height: 0,
                paddingTop: '66.66%',
                backgroundColor: theme.palette.grey[50],
                backgroundImage: backgroundImage,
                backgroundRepeat: "no-repeat",
                backgroundPosition: backgroundPosition,
                backgroundSize: backgroundSize,
            }}
        >
            {isHovered && <HoverHandles selection={selection} />}
            <Box sx={{
                display: 'flex',
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: 0,
                width: "100%",
                p:1,
                backgroundColor: "rgba(0,0,0,0.5)",
            }}>
                <Typography variant="subtitle1"  sx={{color:"white"}}>{selection.customerName}</Typography>
                <Box sx={{ flex: 1 }} />
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-end',
                    justifyContent: 'center',
                }}>
                    <Typography variant="body1" sx={{color:"white"}}>{dayjs(selection.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                    <Typography variant="body1" sx={{color:"white"}}>{selection.pictureIds?.length}{t("pics")}</Typography>
                </Box>
            </Box>
        </Box>

    </Grid>)
  })

const Step5 = inject('snackbarStore', 'pictureStore')(observer(({ snackbarStore, pictureStore }) => {
    const { orderId } = useParams();
    const [order, setOrder] = useState(null);
    const [selections, setSelections] = useState([]);

    const { t } = useTranslation();

    const fetchOrderData = useCallback(() => {
        http.get(`/user/order/${orderId}`)
            .then(response => {
                let orderData = response.data?.order;
                setOrder(orderData);
                let pictures = response.data?.pictures;
                pictureStore.setPictures(pictures);
                pictureStore.setAlbumClaimed(orderData?.Album?.claimed)
            })
            .catch(error => { });
    }, [orderId]);

    const fetchSelections = useCallback(() => {
        http.get(`/user/order/${orderId}/selections`)
            .then(response => {
                let selections = response.data?.selections;
                setSelections(selections);
            })
            .catch(error => { });
    });

    useEffect(() => {
        fetchOrderData();
        fetchSelections();
    }, []);

    if (!order) {
        return <div>Loading...</div>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }} >
            <StepsSideBar order={order} sx={{ width: 200 }} orderId={orderId} />
            <Box sx={{ flex: 1, display: 'flex', flexDirection: "column" }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    pt: 3,
                    pb: 3,
                    pl: 4,
                    pr: 4,
                    borderBottom: 1,
                    borderBottomColor: theme.palette.grey[300],
                    borderBottomStyle: "solid",
                    backgroundColor: theme.palette.grey[50],
                }}>
                    <Typography variant="h3"> {t("Selection Records")}</Typography>
                </Box>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: "row", alignItems: "flex-start", p: 2, justifyContent: "center" }}>
                    {selections.length==0 && <Empty message={t('User not yet selected')} />}
                    {selections.length>0 && <Grid container spacing={2}>
                        {selections.map((selection, index) => {
                            return <SelectionCard key={index} selection={selection} />;
                        })}
                    </Grid>}
                </Box>

            </Box>

        </Box>
    );
}));

export default withAuth(Step5);

import { Box ,TextField,FormControl,Button ,RadioGroup,FormControlLabel,Radio } from '@mui/material';
import { inject, observer } from 'mobx-react';
import React, { useEffect,useCallback, useState } from 'react';
import withAuth from '../../HOC/withAuth';
import { Typography  } from '@mui/material';
import { useTranslation } from 'react-i18next';
import http from '../../http';
import theme from '../../theme';
import dayjs from 'dayjs'


const Support = inject('snackbarStore')(observer(({snackbarStore}) => {
    const {t} = useTranslation();
    const [content,setContent] = useState();
    const [messages,setMessages] = useState([])
    const [contentError,setContentError] = useState(false)
    const [replyType,setReplyType] = useState("messageBoard")

    const validateContent =useCallback( (contentValue)=>{
        if (!contentValue) {
          return t("Content can not be empty");
        }
        if( contentValue.length > 4000){
          return t("Content is too long")
        }
        return '';
    
    },[t])

    const handleInputChange =useCallback( (event) => {
      let { name, value } = event.target;
      value = value.trim()
      if (name === 'content') {
        setContent(value);
        let cError = validateContent(value)
        if ( replyType == "messageBoard" && cError.length > 0 ) {
          setContentError(cError)
        }else{
          setContentError(false)
        }
      }else if(name=="replyType"){
        setReplyType(value)
      }
    },[setContent,setContentError,validateContent]);
  
    const getMyMessages = useCallback(() => {
      http.get(`/user/myMessages`)
        .then(response => {
          setMessages(response.data)
        })
        .catch(error => {});
    }, []);

    const addAMessage = useCallback(()=>{
      let cError = validateContent(content)
      if ( replyType == "messageBoard" && cError.length > 0 ) {
        setContentError(cError)
        return;
      }
      let requestContent = content
      if( replyType == "wechat" ){
        requestContent = t("add my wechat")
      }else if(replyType == "callback"){
        requestContent = t("call me please.")
      }
      http.post(`/user/message/create`,{
        content:requestContent,
        replyType:replyType
      }).then(response=>{
        snackbarStore.showSnackbar('Message submited successfully')
        setContent("")
        getMyMessages()
      }).catch(error => {});
    },[content,replyType,setContentError,validateContent,snackbarStore,getMyMessages])

 

    useEffect(()=>{
      getMyMessages();
    },[getMyMessages])
  
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", p:2  }} >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', marginBottom: 2, height: 40 }}>
        <Typography variant="h3">
          {t("Message Board")}
        </Typography>
        <Typography variant="body2" sx={{ml:1}}>
          {t("If you have any suggestions or complaints, please feel free to leave us a message.")}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 2, flex: 1 }}>
        <Box sx={{ width: "80%",margin: "0px auto" }} >
          <TextField
            multiline
            rows={4} // 设置初始行数
            placeholder={t("Enter your message here")}
            value={content}
            name="content"
            error={!!contentError}
            helperText={contentError || undefined}
            onChange={handleInputChange}
            sx={{
              width:"100%",
            }}
          />
          <Box  sx={{mt:1,display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"center"}}>
            <Box>
              <FormControl  sx={{mt:1,display:"flex",flexDirection:"row",alignItems:"center"}}>
                <Typography >{t("Reply type")}</Typography>
                <RadioGroup
                  aria-label="replyType"
                  name="replyType"
                  value={replyType}
                  onChange={handleInputChange}
                  sx={{ml:3,display:"flex",flexDirection:"row"}}
                >
                  <FormControlLabel value="callback" control={<Radio sx={{p:0}} />} label={t("callback")} />
                  <FormControlLabel value="wechat" control={<Radio sx={{p:0}} />} label={t("wechat")}  />
                  <FormControlLabel value="messageBoard" control={<Radio sx={{p:0}} />} label={t("messageBoard")}  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Button variant="contained" color="primary"  sx={{ mr: 1 }} onClick={() => { 
                addAMessage()
                }}>{t("Submit")}</Button>
          </Box>
          <Box sx={{mt:2}}>
            {messages.map((message)=>{
              return <Box sx={{mt:1,backgroundColor:"#fff",mb:2,p:1}} key={message.id}>
                <Typography  sx={{ml:1,fontSize:"1.1rem",color:"#000"}}>{message.content}</Typography>
                <Typography sx={{ml:1,fontSize:"0.9rem",mt:1,textAlign:"right",color:"#666"}} >{dayjs(message.createdAt).format('YYYY-MM-DD hh:mm:ss')}</Typography>
                {message.replies.map((reply)=>{
                  return <Box key={reply.id}>
                      <Typography  sx={{fontSize:"1.0rem",color:"#000"}}>{t("Admin says:")}</Typography>
                        <Typography  sx={{fontSize:"0.9rem",color:"#666"}}>{reply.content}</Typography>
                    </Box>
                })}
              </Box>
            })}
          </Box>
        </Box>
      </Box>
     
    </Box>
  );
}));

export default withAuth(Support);

import { Typography, Box } from '@mui/material';
import React, { useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { createTextureFromBuffer, createShaderProgram, executeProcessingSteps } from '../util';


const flipertexShaderSource = `#version 300 es
precision mediump float;
in vec2 position;      // 替换了 "attribute"
out vec2 uv;           // 替换了 "varying"

void main() {
    uv = position;
    gl_Position = vec4(2.0 * position.x - 1.0, 1.0 - 2.0 * position.y, 0.0, 1.0);
}
`;
const standardVertexShaderSource = `#version 300 es
precision mediump float;
in vec2 position;      // 替换了 "attribute"
out vec2 uv;           // 替换了 "varying"

void main() {
    uv = position;
    gl_Position = vec4(2.0 * position.x - 1.0, 2.0 * position.y - 1.0, 0.0, 1.0);
}
`;
const fragmentShaderSourceBrightness = `#version 300 es
precision mediump float;

in vec2 uv;                        // 替换了 "varying"
uniform sampler2D uTexture;        // 纹理采样器仍然使用 uniform 声明

uniform float uBrightness;         // 控制亮度
uniform float uContrast;           // 控制对比度

out vec4 outColor;                 // 替代 gl_FragColor 作为输出

void main() {
    vec4 texColor = texture(uTexture, uv);  // 使用 texture 替换 texture2D
    texColor.rgb += uBrightness;
    texColor.rgb = (texColor.rgb - 0.5) * uContrast + 0.5;
    outColor = texColor;                   // 使用 outColor 输出颜色
}
`;
const fragmentShaderSourceLUT = `#version 300 es
precision mediump float;
precision highp sampler3D; 

in vec2 uv;
out vec4 fragColor;
uniform sampler2D uTexture;
uniform sampler3D uLUTTexture;
void main() {
  vec3 imageColor = texture(uTexture, uv).rgb;
  vec3 lutColor = texture(uLUTTexture, imageColor).rgb;
  fragColor = vec4(lutColor, 1.0);
}
`;

const fragmentShaderSourceHSV = `#version 300 es
precision mediump float;
in vec2 uv;
uniform sampler2D uTexture;
uniform float uHue;        // 色调偏移
uniform float uSaturation; // 饱和度系数
uniform float uValue;      // 明度系数
out vec4 outColor;                 // 替代 gl_FragColor 作为输出

// 将RGB颜色转换为HSV颜色
vec3 rgb2hsv(vec3 c) {
    vec4 K = vec4(0.0, -1.0/3.0, 2.0/3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}

// 将HSV颜色转换回RGB颜色
vec3 hsv2rgb(vec3 c) {
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}

void main() {
    vec4 texColor = texture(uTexture, uv);
    vec3 hsv = rgb2hsv(texColor.rgb);

    hsv.x += uHue; // 色调偏移
    hsv.y *= uSaturation; // 饱和度调整
    hsv.z *= uValue; // 明度调整

    // 确保色调在0到1范围内
    hsv.x = mod(hsv.x, 1.0);
    if(hsv.x < 0.0) {
        hsv.x += 1.0;
    }

    outColor = vec4(hsv2rgb(hsv), texColor.a);
}
`

const fragmentShaderSourceWhiteBalance = `#version 300 es
precision mediump float;
in vec2 uv;
uniform sampler2D uTexture;
uniform float uTemperature; // 色温调整系数，正值表示冷色调，负值表示暖色调
out vec4 outColor;

void main() {
    vec4 texColor = texture(uTexture, uv);

    // 根据uTemperature正负值选择颜色调整
    float redAdjustment = max(-uTemperature, 0.0);
    float blueAdjustment = max(uTemperature, 0.0);

    // 调整红色和蓝色通道，确保颜色值在0到1之间
    vec3 adjustedColor = vec3(texColor.r + redAdjustment, texColor.g, texColor.b + blueAdjustment);
    adjustedColor = clamp(adjustedColor, 0.0, 1.0);

    // 输出调整后的颜色
    outColor = vec4(adjustedColor, texColor.a);
}
`;



const RgbCanvas = forwardRef(({ rgbData }, ref) => {
    const canvasRef = useRef(null);

    const adjustCanvasSize = () => {
        if (canvasRef.current) {
            const parent = canvasRef.current.parentNode;
            let { width: parentWidth, height: parentHeight } = parent.getBoundingClientRect();
            parentHeight -= 50;//padding
            parentWidth -= 50;//padding
            canvasRef.current.style.maxHeight = parentHeight + "px";
            canvasRef.current.style.maxWidth = parentWidth + "px";
        }
    };

    // 初始化和调整尺寸
    useEffect(() => {
        if (rgbData != null) {
            adjustCanvasSize();
            drawImage(rgbData, 0, 1, 0, 1, 1,0);
        }

    }, [rgbData]); // 依赖项


    const drawImage = ({ data, width, height }, brightness, contrast, hue, saturation, value,temperature) => {
        if (canvasRef.current && data && width > 0 && height > 0) {
            let steps = [];
            canvasRef.current.width = width;
            canvasRef.current.height = height;
            const gl = canvasRef.current.getContext('webgl2', { preserveDrawingBuffer: true });
            const srcTexture = createTextureFromBuffer(gl, data, width, height);

            var colorProgram = createShaderProgram(gl, flipertexShaderSource, fragmentShaderSourceBrightness);
            var hsvProgram = createShaderProgram(gl, standardVertexShaderSource, fragmentShaderSourceHSV);
            var whiteBalanceProgram = createShaderProgram(gl, standardVertexShaderSource, fragmentShaderSourceWhiteBalance);
            steps.push({
                program: colorProgram,
                uniforms: {
                    uBrightness: brightness,
                    uContrast: contrast,
                }
            });
            steps.push({
                program: hsvProgram,
                uniforms: {
                    uHue: hue,
                    uSaturation: saturation,
                    uValue: value,
                }
            });
            steps.push({
                program: whiteBalanceProgram,
                uniforms: {
                    uTemperature: temperature,
                }
            });
            executeProcessingSteps(gl, srcTexture, width, height, steps);
        }
    };

    const drawWithParams = (brightness, contrast, hue, saturation, value, temperature) => {
        if (rgbData != null) {
            drawImage(rgbData, brightness, contrast, hue, saturation, value, temperature);
        }
    }

    const getResultImage = (currentTaskId, targetWidth) => {
        if (canvasRef.current) {
            const originalCanvas = canvasRef.current;
            const aspectRatio = originalCanvas.width / originalCanvas.height;
    
            // 根据宽高比计算目标高度
            const targetHeight = targetWidth / aspectRatio;
    
            const tempCanvas = document.createElement('canvas');
            tempCanvas.width = targetWidth;
            tempCanvas.height = targetHeight;
            const ctx = tempCanvas.getContext('2d');
    
            // 将原始画布内容绘制到新画布上并缩放
            ctx.drawImage(originalCanvas, 0, 0, originalCanvas.width, originalCanvas.height, 0, 0, targetWidth, targetHeight);
    
            // 其余代码同上，进行数据URL转换和下载操作
            const url = tempCanvas.toDataURL('image/jpg');
            const a = document.createElement('a');
            a.href = url;
            a.download = `${currentTaskId}.jpg`;
            a.click();
        }
    };


    useImperativeHandle(ref, () => ({
        drawWithParams: drawWithParams,
        getResultImage: getResultImage
    }));

    console.log("load component")

    return <canvas ref={canvasRef} />
});


export default React.memo(RgbCanvas);
import React, { useEffect, useCallback } from 'react';
import withAuth from '../../HOC/withAuth';
import { Box, Card, Typography, Button, IconButton ,Grid} from '@mui/material';
import { useTranslation } from 'react-i18next';
import theme from '../../theme';
import AddIcon from '@mui/icons-material/Add';
import Empty from '../../components/Empty';
import { useNavigate } from 'react-router-dom';
import DeleteOutlineOutlinedIcon  from '@mui/icons-material/DeleteOutlineOutlined';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

function PackageCard({ pkg, packageStore, dialogStore }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const deltePackage = useCallback(() => {
    dialogStore.openDialog("Are you sure you want to delete this package?", () => {
      packageStore.deletePackage(pkg.id)
    })
  }, [pkg, packageStore, dialogStore])
  return (
    <Grid item sm={12} md={6} lg={4} >
      <Box sx={{
        boxShadow:1
      }}>
        <Box sx={{ display: "flex", alignItems: 'center', p: 2, justifyContent: "space-between"}} >
          <Typography variant='h4'  color={theme.palette.primary.main}  >
          {pkg.name} 
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb:1, pl:2,pr:2}}>
          <Typography variant="body2" >
            {t("Photo Limit")}: <Typography variant='body'   >{pkg.photoCount}</Typography>
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb:1,  pl:2,pr:2}}>
          <Typography variant="body2" >
            {t("Price")}: ¥ <Typography variant='body'  >{pkg.price}</Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: 2,
            pt:1,
            pb:1,
          }}
        >
          <Button variant="contained" size='small' sx={{ m: 1 }} onClick={() => navigate(`/user/packages/${pkg.id}/edit`)}>
            {t("Edit")}
          </Button>
          <IconButton aria-label="delete" sx={{ mr: 1 }} onClick={deltePackage}>
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
}

const PackageList = inject('packageStore', 'dialogStore')(observer(({ packageStore, dialogStore }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    packageStore.fetchPackages()
  }, [packageStore]);

  return (

    <Box sx={{display:"flex",flexDirection:"column",height:"100%", p:2 }} >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
        <Typography variant="h3">
          {t("Package List")}
        </Typography>

        <Button variant="contained" color="primary" onClick={() => navigate('/user/packages/create')}>
          <AddIcon fontSize='small' />{t("Create Package")}
        </Button>
      </Box>
      <Grid container spacing={2}   >
        {packageStore?.packages.length > 0 ? (
          packageStore?.packages.map(pkg => <PackageCard key={pkg.id} pkg={pkg} packageStore={packageStore} dialogStore={dialogStore} />)
        ) : (
          <Empty message={t("No packages available.")} >
            <Button sx={{mt:2}} variant="contained" color="primary" onClick={() => navigate('/user/packages/create')}>
              <AddIcon fontSize='small' />{t("Create Package")}
            </Button>
          </Empty>
        )}
      </Grid>
    </Box>
  );
}));

export default withAuth(PackageList);
